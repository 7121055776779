.productfull {
    /**
     * Product image (Left)
     */
    &__image {
        position: relative;

        &-link {
            display: block;
            position: relative;
            text-align: center; }

        &-zoom {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 24px; } }

    &__label {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 10; }

    // Thumbs
    &__img-thumbs {
        margin-top: 15px;

        > span {
            align-items: center;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; }

        .owl-stage-outer {
            padding-left: 5px;
            padding-right: 5px; }

        .owl-item {
            border: 1px solid $gray-400; }

        img {
            // border: 1px solid $gray-400
            cursor: pointer;
            transition: border-color .3s ease;

            &:hover {
                border-color: lighten($primary, 20%);
                transition: border-color .3s ease; } } }

    &__video-preview {
        position: relative;
        display: block;

        &::before {
            @include sprite($youtube);
            content: "";
            display: block;
            @include pos-center;
            z-index: 10; }

        img {
            border: 1px solid $gray-400;
            padding: 1px; } }


    /**
     * Right column
     */
    &__return {
        align-items: center;
        color: $gray-600;
        display: inline-flex;
        font-size: 14px;

        &--primary {
            color: $primary; }

        &:hover {
            text-decoration: none; } }

    &__title {
        font-size: 24px; }

    &__instock {
        color: $white;
        font-size: 12px;
        font-weight: 500;
        padding-left: 8px;
        padding-right: 8px;
        vertical-align: bottom;
        transform: translateY(-4px);
        margin-left: 10px; }

    &__info {
        color: $gray-500;
        font-size: 14px; }

    /**
     * Price
     */
    &__price-row {
        align-items: flex-end;
        display: flex; }

    &__price {
        @include responsive-tlt(20px, 30px);
        font-weight: 700;
        line-height: normal;

        &-curr,
        .currencycode {
            font-size: 18px;
 } }            // margin-left: 5px

    &__old-price {
        color: rgba($gray-800, 0.3);
        text-decoration: line-through;
        margin-left: 15px;
        line-height: normal;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 2px; }

    /**
     * Characteristics
     */
    &__charact {
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 30px;
        margin-top: 30px;
        @include media-breakpoint-up(lg) {
            margin-top: 40px; }

        &-tlt {
            color: $gray-800; }

        &-row {
            display: flex;
            font-size: 14px;
            font-weight: 500;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 10px; }

            &-tlt {
               color: $gray-500; }

            &-val {
               color: $gray-600; } } }

    /**
     * Buttons
     */
    &__btns {
        display: flex;
        margin-top: 30px;

        .btn {
            margin-right: 8px;

            @include media-breakpoint-down(xs) {
                width: 100%; }

            .icon {
                font-size: 18px; } } }

    &__btn-favorit {
        &.btn {
            background-color: $gray-300;
            width: 50px;
            flex-shrink: 0;

            &:hover {
                background-color: darken($gray-300, 10%); } } } }


