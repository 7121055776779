.cart-table {
    &__title {
        align-items: center;
        display: flex;

        &-count {
            align-items: center;
            background-color: #888;
            border-radius: 50%;
            color: $white;
            display: flex;
            font-size: 12px;
            font-weight: 700;
            margin-left: 10px;
            justify-content: center;
            height: 25px;
            width: 25px; } }

    &__checkout {
        border-bottom: 1px solid $gray-300; }

    .table {
        font-size: 14px;

        th {
            color: $gray-500;
            font-size: 14px; }

        th,
        td {
            // border-color: $gray-f1
            text-align: center;
            vertical-align: middle;
            line-height: 1.2;

            &.cart-table__product {
                text-align: left; }

            &.product-name {
                width: 45%; }

            &.qty {
                width: 20%; } }

        tr {
            &:first-child {
                th {
                    border-top: none; } } } }

    &__image {
        padding-left: 10px;

        img {
            border-radius: $border-radius;
            max-width: 70px;
            position: relative;
            z-index: 0; } }

    &__product-right {
        position: relative; }

    &__product-name {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;

        a {
            color: $gray-800;

            &:hover {
                color: $primary;
                text-decoration: none; } } }

    .list_attribute,
    .list_free_attribute,
    .jshop-code-prod {
        font-size: 13px;

        p {
            color: $gray-500;
            font-size: 13px;
            margin-bottom: 4px; } }

    &__reload {
        .icon {
            font-size: 14px; } }

    &__summ {
        font-size: 14px;
        font-weight: 700;
        @include media-breakpoint-up(lg) {
            font-size: 18px;
            width: 15%; }

        &-curr,
        .currencycode {
            font-size: 14px; } }

    &__remove {
        a {
            color: #bebebe;

            .icon {
                font-size: 10px; }

            &:hover {
                color: $primary; } } }


    // subTotal
    &__subtotal {
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            justify-content: space-between; } }

    &__total {
        color: $gray-500;
        font-size: 14px;

        &-row {
            display: flex;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 5px; }

            &--itog {
                color: $gray-800;
                font-size: 18px;
                font-weight: 700; } }

        &-summ {
            color: $gray-800;
            font-weight: 700; } }

    // Nav
    &__nav {
        .btn {
            .icon {
                font-size: 20px; } }

        &-prev {
            .icon {
                margin-right: 8px; } }

        &-next {
            .icon {
                margin-left: 8px; } } }

    // Prod qty
    .jshop-prod-qty {
        .form-control {
            max-width: 65px; } } }
