.extra_fields {
    &__row {
        align-items: center;
        display: flex;
        font-size: 14px;
        line-height: normal;

        &:not(:last-child) {
            margin-bottom: 15px; } }

    &__lbl {
        display: block;
        margin-right: 5px;
        font-weight: 700; } }
