.header {
    padding-top: 15px;
    padding-bottom: 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1012;
    height: 55px;
    @include media-breakpoint-down(md) {
        background-color: $white;
        box-shadow: 0 0 5px rgba($black, 0.25); }
    @include media-breakpoint-up(md) {
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto; }
    @include media-breakpoint-up(lg) {
        position: static; }

    &__top {
        padding-bottom: 10px;

        > .container {
            align-items: center;
            display: flex;
            justify-content: flex-end; } }

    &__bottom {
        > .container {
            position: relative; }

        &-middle {
            align-items: center;
            display: flex; }

        &-right {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
                a {
                    color: $gray-800;
                    display: block;

                    .icon {
                        font-size: 25px; } } } } }

    &__phone {
        align-items: flex-end;
        display: flex;
        flex-shrink: 0;

        &-small {
            font-size: 12px;
            margin-bottom: 0;
            padding-left: 10px; }

        &-icon {
            margin-right: 10px;

            .icon {
                color: $gray-800;
                font-size: 33px; } }
        &-tel {
            font-size: 20px;
            font-weight: 500;

            &-link {
                color: $gray-800;

                &:hover {
                    color: $primary;
                    text-decoration: none; } }

            .icon-whatsapp {
                font-size: 18px;
                fill: $green;
                margin-left: 10px; } } } }
