.jshop-product-qty {
    $self: &;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    display: flex;
    position: relative;
    height: 50px;
    padding: 9px;
    overflow: hidden;
    flex-shrink: 0;

    &__input {
        color: $gray-800;
        border: none;
        width: 100%;
        max-width: 45px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        height: 100%;

        &:focus {
            outline: none; } }

    &__btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 16px; }

    &__btn {
        background-color: transparent;
        align-items: center;
        border: none;
        display: flex;
        height: 16px;
        width: 100%;
        justify-content: center;

        &::before {
            content: "";
            height: 0;
            width: 0; }

        &:focus {
            outline: none; }

        &:hover {
            &::before {
                border-top-color: $primary;
                border-bottom-color: $primary; } }

        &--plus {
            &::before {
                border-bottom: 4px solid #888;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent; } }

        &--minus {
            &::before {
                border-top: 4px solid #888;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent; } } }

    /**
     * Sizes
     */

    // Middle
    &--md {
        padding: 5px;
        height: 40px; }

    #{$self} {
        &__input {
            max-width: 35px;
            font-size: 14px; } }

    // Small
    &--sm {
        padding: 5px;
        height: 30px;

        #{$self} {
            &__input {
                max-width: 28px;
                font-size: 14px;
                height: 100% !important; } } } }
