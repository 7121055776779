/* ============================================================================
   INCLUDS
   @mixin ======================================================================== */
/*
 * Color system
 * ---------------------------------------------
 */
/**
 * Buttons
 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-arrow-left {
  width: 1em; }

.icon-arrow-right {
  width: 1em; }

.icon-arrow-up {
  width: 1em; }

.icon-cancel {
  width: 1em; }

.icon-cart {
  width: 1em; }

.icon-eye {
  width: 1em; }

.icon-heart {
  width: 1em; }

.icon-icon_list {
  width: 1em; }

.icon-phone-call {
  width: 1em; }

.icon-placeholder {
  width: 1em; }

.icon-refresh {
  width: 1em; }

.icon-search {
  width: 1em; }

.icon-shopping-cart {
  width: 1em; }

.icon-star {
  width: 1em; }

.icon-user {
  width: 1em; }

.icon-whatsapp {
  width: 1em; }

.icon-zoom-in {
  width: 1em; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2f3e4c;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #f46a57;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #ef2b10;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6a7d90;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6a7d90; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2f3e4c; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #2f3e4c;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcd5d0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f9b2a8; }

.table-hover .table-primary:hover {
  background-color: #fac0b8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fac0b8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d5dbe0; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b2bbc5; }

.table-hover .table-secondary:hover {
  background-color: #c6ced5; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c6ced5; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c8ecd0; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9adba8; }

.table-hover .table-success:hover {
  background-color: #b5e5c0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b5e5c0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcd5d0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f9b2a8; }

.table-hover .table-danger:hover {
  background-color: #fac0b8; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fac0b8; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c9cd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #939ba2; }

.table-hover .table-dark:hover {
  background-color: #b7bcc1; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b7bcc1; }

.table-brown,
.table-brown > th,
.table-brown > td {
  background-color: #e9dccc; }

.table-brown th,
.table-brown td,
.table-brown thead th,
.table-brown tbody + tbody {
  border-color: #d6bea0; }

.table-hover .table-brown:hover {
  background-color: #e1d0ba; }
  .table-hover .table-brown:hover > td,
  .table-hover .table-brown:hover > th {
    background-color: #e1d0ba; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #2f3e4c;
  border-color: #3e5164; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #2f3e4c; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #3e5164; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #fcd4cf;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
  .form-control::placeholder {
    color: #6a7d90;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #2f3e4c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6a7d90; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #3cba58; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(60, 186, 88, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3cba58;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233cba58' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #3cba58;
    box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #3cba58;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3e4c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233cba58' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #3cba58;
    box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3cba58; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3cba58; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #3cba58; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5ecb76;
  background-color: #5ecb76; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3cba58; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3cba58; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #3cba58;
  box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a57; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(244, 106, 87, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f46a57;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f46a57' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a57' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f46a57;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f46a57;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3e4c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f46a57' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a57' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f46a57;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f46a57; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f46a57; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f46a57; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f79487;
  background-color: #f79487; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f46a57; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f46a57; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f46a57;
  box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #2f3e4c;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #2f3e4c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #f46a57;
  border-color: #f46a57; }
  .btn-primary:hover {
    color: #fff;
    background-color: #f24a33;
    border-color: #f14027; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #f24a33;
    border-color: #f14027;
    box-shadow: 0 0 0 0.2rem rgba(246, 128, 112, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #f46a57;
    border-color: #f46a57; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #f14027;
    border-color: #f0351b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 128, 112, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6a7d90;
  border-color: #6a7d90; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6a7a;
    border-color: #546473; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6a7a;
    border-color: #546473;
    box-shadow: 0 0 0 0.2rem rgba(128, 145, 161, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6a7d90;
    border-color: #6a7d90; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #546473;
    border-color: #4f5d6b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 145, 161, 0.5); }

.btn-success {
  color: #fff;
  background-color: #3cba58;
  border-color: #3cba58; }
  .btn-success:hover {
    color: #fff;
    background-color: #339d4a;
    border-color: #309346; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #339d4a;
    border-color: #309346;
    box-shadow: 0 0 0 0.2rem rgba(89, 196, 113, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #3cba58;
    border-color: #3cba58; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #309346;
    border-color: #2c8a41; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 196, 113, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f46a57;
  border-color: #f46a57; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f24a33;
    border-color: #f14027; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #f24a33;
    border-color: #f14027;
    box-shadow: 0 0 0 0.2rem rgba(246, 128, 112, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f46a57;
    border-color: #f46a57; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f14027;
    border-color: #f0351b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 128, 112, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #2f3e4c;
  border-color: #2f3e4c; }
  .btn-dark:hover {
    color: #fff;
    background-color: #202b34;
    border-color: #1c242c; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #202b34;
    border-color: #1c242c;
    box-shadow: 0 0 0 0.2rem rgba(78, 91, 103, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2f3e4c;
    border-color: #2f3e4c; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1c242c;
    border-color: #171e25; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 91, 103, 0.5); }

.btn-brown {
  color: #fff;
  background-color: #b18248;
  border-color: #b18248; }
  .btn-brown:hover {
    color: #fff;
    background-color: #966e3d;
    border-color: #8d6739; }
  .btn-brown:focus, .btn-brown.focus {
    color: #fff;
    background-color: #966e3d;
    border-color: #8d6739;
    box-shadow: 0 0 0 0.2rem rgba(189, 149, 99, 0.5); }
  .btn-brown.disabled, .btn-brown:disabled {
    color: #fff;
    background-color: #b18248;
    border-color: #b18248; }
  .btn-brown:not(:disabled):not(.disabled):active, .btn-brown:not(:disabled):not(.disabled).active,
  .show > .btn-brown.dropdown-toggle {
    color: #fff;
    background-color: #8d6739;
    border-color: #846136; }
    .btn-brown:not(:disabled):not(.disabled):active:focus, .btn-brown:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(189, 149, 99, 0.5); }

.btn-outline-primary {
  color: #f46a57;
  border-color: #f46a57; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #f46a57;
    border-color: #f46a57; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #f46a57;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #f46a57;
    border-color: #f46a57; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.5); }

.btn-outline-secondary {
  color: #6a7d90;
  border-color: #6a7d90; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6a7d90;
    border-color: #6a7d90; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 125, 144, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6a7d90;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6a7d90;
    border-color: #6a7d90; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(106, 125, 144, 0.5); }

.btn-outline-success {
  color: #3cba58;
  border-color: #3cba58; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #3cba58;
    border-color: #3cba58; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #3cba58;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #3cba58;
    border-color: #3cba58; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #f46a57;
  border-color: #f46a57; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f46a57;
    border-color: #f46a57; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f46a57;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f46a57;
    border-color: #f46a57; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #2f3e4c;
  border-color: #2f3e4c; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #2f3e4c;
    border-color: #2f3e4c; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 62, 76, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2f3e4c;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #2f3e4c;
    border-color: #2f3e4c; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 62, 76, 0.5); }

.btn-outline-brown {
  color: #b18248;
  border-color: #b18248; }
  .btn-outline-brown:hover {
    color: #fff;
    background-color: #b18248;
    border-color: #b18248; }
  .btn-outline-brown:focus, .btn-outline-brown.focus {
    box-shadow: 0 0 0 0.2rem rgba(177, 130, 72, 0.5); }
  .btn-outline-brown.disabled, .btn-outline-brown:disabled {
    color: #b18248;
    background-color: transparent; }
  .btn-outline-brown:not(:disabled):not(.disabled):active, .btn-outline-brown:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brown.dropdown-toggle {
    color: #fff;
    background-color: #b18248;
    border-color: #b18248; }
    .btn-outline-brown:not(:disabled):not(.disabled):active:focus, .btn-outline-brown:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(177, 130, 72, 0.5); }

.btn-link {
  font-weight: 400;
  color: #f46a57;
  text-decoration: none; }
  .btn-link:hover {
    color: #ef2b10;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6a7d90;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 4px; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #f46a57;
    background-color: #f46a57; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #fcd4cf; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6a7d90; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #f46a57;
  background-color: #f46a57; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 106, 87, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(244, 106, 87, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 106, 87, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(244, 106, 87, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3e4c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #fcd4cf;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6a7d90;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #fcd4cf;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 106, 87, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #f46a57;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #f46a57;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #f46a57;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #f46a57; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #f14027; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6a7d90; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #546473; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(106, 125, 144, 0.5); }

.badge-success {
  color: #fff;
  background-color: #3cba58; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #309346; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(60, 186, 88, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f46a57; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #f14027; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 106, 87, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #2f3e4c; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1c242c; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 62, 76, 0.5); }

.badge-brown {
  color: #fff;
  background-color: #b18248; }
  a.badge-brown:hover, a.badge-brown:focus {
    color: #fff;
    background-color: #8d6739; }
  a.badge-brown:focus, a.badge-brown.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(177, 130, 72, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #7f372d;
  background-color: #fde1dd;
  border-color: #fcd5d0; }
  .alert-primary hr {
    border-top-color: #fac0b8; }
  .alert-primary .alert-link {
    color: #592720; }

.alert-secondary {
  color: #37414b;
  background-color: #e1e5e9;
  border-color: #d5dbe0; }
  .alert-secondary hr {
    border-top-color: #c6ced5; }
  .alert-secondary .alert-link {
    color: #21282e; }

.alert-success {
  color: #1f612e;
  background-color: #d8f1de;
  border-color: #c8ecd0; }
  .alert-success hr {
    border-top-color: #b5e5c0; }
  .alert-success .alert-link {
    color: #133a1c; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #7f372d;
  background-color: #fde1dd;
  border-color: #fcd5d0; }
  .alert-danger hr {
    border-top-color: #fac0b8; }
  .alert-danger .alert-link {
    color: #592720; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #182028;
  background-color: #d5d8db;
  border-color: #c5c9cd; }
  .alert-dark hr {
    border-top-color: #b7bcc1; }
  .alert-dark .alert-link {
    color: #050708; }

.alert-brown {
  color: #5c4425;
  background-color: #efe6da;
  border-color: #e9dccc; }
  .alert-brown hr {
    border-top-color: #e1d0ba; }
  .alert-brown .alert-link {
    color: #382916; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #f46a57 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #f14027 !important; }

.bg-secondary {
  background-color: #6a7d90 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #546473 !important; }

.bg-success {
  background-color: #3cba58 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #309346 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #f46a57 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f14027 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #2f3e4c !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1c242c !important; }

.bg-brown {
  background-color: #b18248 !important; }

a.bg-brown:hover, a.bg-brown:focus,
button.bg-brown:hover,
button.bg-brown:focus {
  background-color: #8d6739 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-10 {
  margin: 0.625rem !important; }

.mt-10,
.my-10 {
  margin-top: 0.625rem !important; }

.mr-10,
.mx-10 {
  margin-right: 0.625rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 0.625rem !important; }

.ml-10,
.mx-10 {
  margin-left: 0.625rem !important; }

.m-15 {
  margin: 0.9375rem !important; }

.mt-15,
.my-15 {
  margin-top: 0.9375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 0.9375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 0.9375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 0.9375rem !important; }

.m-20 {
  margin: 1.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 1.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important; }

.m-25 {
  margin: 1.5625rem !important; }

.mt-25,
.my-25 {
  margin-top: 1.5625rem !important; }

.mr-25,
.mx-25 {
  margin-right: 1.5625rem !important; }

.mb-25,
.my-25 {
  margin-bottom: 1.5625rem !important; }

.ml-25,
.mx-25 {
  margin-left: 1.5625rem !important; }

.m-30 {
  margin: 1.875rem !important; }

.mt-30,
.my-30 {
  margin-top: 1.875rem !important; }

.mr-30,
.mx-30 {
  margin-right: 1.875rem !important; }

.mb-30,
.my-30 {
  margin-bottom: 1.875rem !important; }

.ml-30,
.mx-30 {
  margin-left: 1.875rem !important; }

.m-40 {
  margin: 2.5rem !important; }

.mt-40,
.my-40 {
  margin-top: 2.5rem !important; }

.mr-40,
.mx-40 {
  margin-right: 2.5rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 2.5rem !important; }

.ml-40,
.mx-40 {
  margin-left: 2.5rem !important; }

.m-50 {
  margin: 3.125rem !important; }

.mt-50,
.my-50 {
  margin-top: 3.125rem !important; }

.mr-50,
.mx-50 {
  margin-right: 3.125rem !important; }

.mb-50,
.my-50 {
  margin-bottom: 3.125rem !important; }

.ml-50,
.mx-50 {
  margin-left: 3.125rem !important; }

.m-60 {
  margin: 3.75rem !important; }

.mt-60,
.my-60 {
  margin-top: 3.75rem !important; }

.mr-60,
.mx-60 {
  margin-right: 3.75rem !important; }

.mb-60,
.my-60 {
  margin-bottom: 3.75rem !important; }

.ml-60,
.mx-60 {
  margin-left: 3.75rem !important; }

.m-80 {
  margin: 5rem !important; }

.mt-80,
.my-80 {
  margin-top: 5rem !important; }

.mr-80,
.mx-80 {
  margin-right: 5rem !important; }

.mb-80,
.my-80 {
  margin-bottom: 5rem !important; }

.ml-80,
.mx-80 {
  margin-left: 5rem !important; }

.m-90 {
  margin: 5.625rem !important; }

.mt-90,
.my-90 {
  margin-top: 5.625rem !important; }

.mr-90,
.mx-90 {
  margin-right: 5.625rem !important; }

.mb-90,
.my-90 {
  margin-bottom: 5.625rem !important; }

.ml-90,
.mx-90 {
  margin-left: 5.625rem !important; }

.m-100 {
  margin: 6.25rem !important; }

.mt-100,
.my-100 {
  margin-top: 6.25rem !important; }

.mr-100,
.mx-100 {
  margin-right: 6.25rem !important; }

.mb-100,
.my-100 {
  margin-bottom: 6.25rem !important; }

.ml-100,
.mx-100 {
  margin-left: 6.25rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-10 {
  padding: 0.625rem !important; }

.pt-10,
.py-10 {
  padding-top: 0.625rem !important; }

.pr-10,
.px-10 {
  padding-right: 0.625rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 0.625rem !important; }

.pl-10,
.px-10 {
  padding-left: 0.625rem !important; }

.p-15 {
  padding: 0.9375rem !important; }

.pt-15,
.py-15 {
  padding-top: 0.9375rem !important; }

.pr-15,
.px-15 {
  padding-right: 0.9375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 0.9375rem !important; }

.pl-15,
.px-15 {
  padding-left: 0.9375rem !important; }

.p-20 {
  padding: 1.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 1.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 1.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 1.25rem !important; }

.p-25 {
  padding: 1.5625rem !important; }

.pt-25,
.py-25 {
  padding-top: 1.5625rem !important; }

.pr-25,
.px-25 {
  padding-right: 1.5625rem !important; }

.pb-25,
.py-25 {
  padding-bottom: 1.5625rem !important; }

.pl-25,
.px-25 {
  padding-left: 1.5625rem !important; }

.p-30 {
  padding: 1.875rem !important; }

.pt-30,
.py-30 {
  padding-top: 1.875rem !important; }

.pr-30,
.px-30 {
  padding-right: 1.875rem !important; }

.pb-30,
.py-30 {
  padding-bottom: 1.875rem !important; }

.pl-30,
.px-30 {
  padding-left: 1.875rem !important; }

.p-40 {
  padding: 2.5rem !important; }

.pt-40,
.py-40 {
  padding-top: 2.5rem !important; }

.pr-40,
.px-40 {
  padding-right: 2.5rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 2.5rem !important; }

.pl-40,
.px-40 {
  padding-left: 2.5rem !important; }

.p-50 {
  padding: 3.125rem !important; }

.pt-50,
.py-50 {
  padding-top: 3.125rem !important; }

.pr-50,
.px-50 {
  padding-right: 3.125rem !important; }

.pb-50,
.py-50 {
  padding-bottom: 3.125rem !important; }

.pl-50,
.px-50 {
  padding-left: 3.125rem !important; }

.p-60 {
  padding: 3.75rem !important; }

.pt-60,
.py-60 {
  padding-top: 3.75rem !important; }

.pr-60,
.px-60 {
  padding-right: 3.75rem !important; }

.pb-60,
.py-60 {
  padding-bottom: 3.75rem !important; }

.pl-60,
.px-60 {
  padding-left: 3.75rem !important; }

.p-80 {
  padding: 5rem !important; }

.pt-80,
.py-80 {
  padding-top: 5rem !important; }

.pr-80,
.px-80 {
  padding-right: 5rem !important; }

.pb-80,
.py-80 {
  padding-bottom: 5rem !important; }

.pl-80,
.px-80 {
  padding-left: 5rem !important; }

.p-90 {
  padding: 5.625rem !important; }

.pt-90,
.py-90 {
  padding-top: 5.625rem !important; }

.pr-90,
.px-90 {
  padding-right: 5.625rem !important; }

.pb-90,
.py-90 {
  padding-bottom: 5.625rem !important; }

.pl-90,
.px-90 {
  padding-left: 5.625rem !important; }

.p-100 {
  padding: 6.25rem !important; }

.pt-100,
.py-100 {
  padding-top: 6.25rem !important; }

.pr-100,
.px-100 {
  padding-right: 6.25rem !important; }

.pb-100,
.py-100 {
  padding-bottom: 6.25rem !important; }

.pl-100,
.px-100 {
  padding-left: 6.25rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n10 {
  margin: -0.625rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -0.625rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -0.625rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -0.625rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -0.625rem !important; }

.m-n15 {
  margin: -0.9375rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -0.9375rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -0.9375rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -0.9375rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -0.9375rem !important; }

.m-n20 {
  margin: -1.25rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -1.25rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -1.25rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -1.25rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -1.25rem !important; }

.m-n25 {
  margin: -1.5625rem !important; }

.mt-n25,
.my-n25 {
  margin-top: -1.5625rem !important; }

.mr-n25,
.mx-n25 {
  margin-right: -1.5625rem !important; }

.mb-n25,
.my-n25 {
  margin-bottom: -1.5625rem !important; }

.ml-n25,
.mx-n25 {
  margin-left: -1.5625rem !important; }

.m-n30 {
  margin: -1.875rem !important; }

.mt-n30,
.my-n30 {
  margin-top: -1.875rem !important; }

.mr-n30,
.mx-n30 {
  margin-right: -1.875rem !important; }

.mb-n30,
.my-n30 {
  margin-bottom: -1.875rem !important; }

.ml-n30,
.mx-n30 {
  margin-left: -1.875rem !important; }

.m-n40 {
  margin: -2.5rem !important; }

.mt-n40,
.my-n40 {
  margin-top: -2.5rem !important; }

.mr-n40,
.mx-n40 {
  margin-right: -2.5rem !important; }

.mb-n40,
.my-n40 {
  margin-bottom: -2.5rem !important; }

.ml-n40,
.mx-n40 {
  margin-left: -2.5rem !important; }

.m-n50 {
  margin: -3.125rem !important; }

.mt-n50,
.my-n50 {
  margin-top: -3.125rem !important; }

.mr-n50,
.mx-n50 {
  margin-right: -3.125rem !important; }

.mb-n50,
.my-n50 {
  margin-bottom: -3.125rem !important; }

.ml-n50,
.mx-n50 {
  margin-left: -3.125rem !important; }

.m-n60 {
  margin: -3.75rem !important; }

.mt-n60,
.my-n60 {
  margin-top: -3.75rem !important; }

.mr-n60,
.mx-n60 {
  margin-right: -3.75rem !important; }

.mb-n60,
.my-n60 {
  margin-bottom: -3.75rem !important; }

.ml-n60,
.mx-n60 {
  margin-left: -3.75rem !important; }

.m-n80 {
  margin: -5rem !important; }

.mt-n80,
.my-n80 {
  margin-top: -5rem !important; }

.mr-n80,
.mx-n80 {
  margin-right: -5rem !important; }

.mb-n80,
.my-n80 {
  margin-bottom: -5rem !important; }

.ml-n80,
.mx-n80 {
  margin-left: -5rem !important; }

.m-n90 {
  margin: -5.625rem !important; }

.mt-n90,
.my-n90 {
  margin-top: -5.625rem !important; }

.mr-n90,
.mx-n90 {
  margin-right: -5.625rem !important; }

.mb-n90,
.my-n90 {
  margin-bottom: -5.625rem !important; }

.ml-n90,
.mx-n90 {
  margin-left: -5.625rem !important; }

.m-n100 {
  margin: -6.25rem !important; }

.mt-n100,
.my-n100 {
  margin-top: -6.25rem !important; }

.mr-n100,
.mx-n100 {
  margin-right: -6.25rem !important; }

.mb-n100,
.my-n100 {
  margin-bottom: -6.25rem !important; }

.ml-n100,
.mx-n100 {
  margin-left: -6.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-10 {
    margin: 0.625rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 0.625rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 0.625rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 0.625rem !important; }
  .m-sm-15 {
    margin: 0.9375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 0.9375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 0.9375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 0.9375rem !important; }
  .m-sm-20 {
    margin: 1.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.25rem !important; }
  .m-sm-25 {
    margin: 1.5625rem !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 1.5625rem !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 1.5625rem !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 1.5625rem !important; }
  .m-sm-30 {
    margin: 1.875rem !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 1.875rem !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 1.875rem !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 1.875rem !important; }
  .m-sm-40 {
    margin: 2.5rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 2.5rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 2.5rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 2.5rem !important; }
  .m-sm-50 {
    margin: 3.125rem !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 3.125rem !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 3.125rem !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 3.125rem !important; }
  .m-sm-60 {
    margin: 3.75rem !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 3.75rem !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 3.75rem !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 3.75rem !important; }
  .m-sm-80 {
    margin: 5rem !important; }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 5rem !important; }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 5rem !important; }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 5rem !important; }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 5rem !important; }
  .m-sm-90 {
    margin: 5.625rem !important; }
  .mt-sm-90,
  .my-sm-90 {
    margin-top: 5.625rem !important; }
  .mr-sm-90,
  .mx-sm-90 {
    margin-right: 5.625rem !important; }
  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 5.625rem !important; }
  .ml-sm-90,
  .mx-sm-90 {
    margin-left: 5.625rem !important; }
  .m-sm-100 {
    margin: 6.25rem !important; }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 6.25rem !important; }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 6.25rem !important; }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 6.25rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-10 {
    padding: 0.625rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 0.625rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 0.625rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 0.625rem !important; }
  .p-sm-15 {
    padding: 0.9375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 0.9375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 0.9375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 0.9375rem !important; }
  .p-sm-20 {
    padding: 1.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.25rem !important; }
  .p-sm-25 {
    padding: 1.5625rem !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 1.5625rem !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 1.5625rem !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 1.5625rem !important; }
  .p-sm-30 {
    padding: 1.875rem !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 1.875rem !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 1.875rem !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 1.875rem !important; }
  .p-sm-40 {
    padding: 2.5rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 2.5rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 2.5rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 2.5rem !important; }
  .p-sm-50 {
    padding: 3.125rem !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 3.125rem !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 3.125rem !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 3.125rem !important; }
  .p-sm-60 {
    padding: 3.75rem !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 3.75rem !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 3.75rem !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 3.75rem !important; }
  .p-sm-80 {
    padding: 5rem !important; }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 5rem !important; }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 5rem !important; }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 5rem !important; }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 5rem !important; }
  .p-sm-90 {
    padding: 5.625rem !important; }
  .pt-sm-90,
  .py-sm-90 {
    padding-top: 5.625rem !important; }
  .pr-sm-90,
  .px-sm-90 {
    padding-right: 5.625rem !important; }
  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 5.625rem !important; }
  .pl-sm-90,
  .px-sm-90 {
    padding-left: 5.625rem !important; }
  .p-sm-100 {
    padding: 6.25rem !important; }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 6.25rem !important; }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 6.25rem !important; }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 6.25rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n10 {
    margin: -0.625rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -0.625rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -0.625rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -0.625rem !important; }
  .m-sm-n15 {
    margin: -0.9375rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -0.9375rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -0.9375rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -0.9375rem !important; }
  .m-sm-n20 {
    margin: -1.25rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -1.25rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -1.25rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -1.25rem !important; }
  .m-sm-n25 {
    margin: -1.5625rem !important; }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -1.5625rem !important; }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -1.5625rem !important; }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -1.5625rem !important; }
  .m-sm-n30 {
    margin: -1.875rem !important; }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -1.875rem !important; }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -1.875rem !important; }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -1.875rem !important; }
  .m-sm-n40 {
    margin: -2.5rem !important; }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -2.5rem !important; }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -2.5rem !important; }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -2.5rem !important; }
  .m-sm-n50 {
    margin: -3.125rem !important; }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -3.125rem !important; }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -3.125rem !important; }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -3.125rem !important; }
  .m-sm-n60 {
    margin: -3.75rem !important; }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -3.75rem !important; }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -3.75rem !important; }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -3.75rem !important; }
  .m-sm-n80 {
    margin: -5rem !important; }
  .mt-sm-n80,
  .my-sm-n80 {
    margin-top: -5rem !important; }
  .mr-sm-n80,
  .mx-sm-n80 {
    margin-right: -5rem !important; }
  .mb-sm-n80,
  .my-sm-n80 {
    margin-bottom: -5rem !important; }
  .ml-sm-n80,
  .mx-sm-n80 {
    margin-left: -5rem !important; }
  .m-sm-n90 {
    margin: -5.625rem !important; }
  .mt-sm-n90,
  .my-sm-n90 {
    margin-top: -5.625rem !important; }
  .mr-sm-n90,
  .mx-sm-n90 {
    margin-right: -5.625rem !important; }
  .mb-sm-n90,
  .my-sm-n90 {
    margin-bottom: -5.625rem !important; }
  .ml-sm-n90,
  .mx-sm-n90 {
    margin-left: -5.625rem !important; }
  .m-sm-n100 {
    margin: -6.25rem !important; }
  .mt-sm-n100,
  .my-sm-n100 {
    margin-top: -6.25rem !important; }
  .mr-sm-n100,
  .mx-sm-n100 {
    margin-right: -6.25rem !important; }
  .mb-sm-n100,
  .my-sm-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n100,
  .mx-sm-n100 {
    margin-left: -6.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-10 {
    margin: 0.625rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 0.625rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 0.625rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 0.625rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 0.625rem !important; }
  .m-md-15 {
    margin: 0.9375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 0.9375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 0.9375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 0.9375rem !important; }
  .m-md-20 {
    margin: 1.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.25rem !important; }
  .m-md-25 {
    margin: 1.5625rem !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 1.5625rem !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 1.5625rem !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 1.5625rem !important; }
  .m-md-30 {
    margin: 1.875rem !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 1.875rem !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 1.875rem !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 1.875rem !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 1.875rem !important; }
  .m-md-40 {
    margin: 2.5rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 2.5rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 2.5rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 2.5rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 2.5rem !important; }
  .m-md-50 {
    margin: 3.125rem !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 3.125rem !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 3.125rem !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 3.125rem !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 3.125rem !important; }
  .m-md-60 {
    margin: 3.75rem !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 3.75rem !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 3.75rem !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 3.75rem !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 3.75rem !important; }
  .m-md-80 {
    margin: 5rem !important; }
  .mt-md-80,
  .my-md-80 {
    margin-top: 5rem !important; }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 5rem !important; }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 5rem !important; }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 5rem !important; }
  .m-md-90 {
    margin: 5.625rem !important; }
  .mt-md-90,
  .my-md-90 {
    margin-top: 5.625rem !important; }
  .mr-md-90,
  .mx-md-90 {
    margin-right: 5.625rem !important; }
  .mb-md-90,
  .my-md-90 {
    margin-bottom: 5.625rem !important; }
  .ml-md-90,
  .mx-md-90 {
    margin-left: 5.625rem !important; }
  .m-md-100 {
    margin: 6.25rem !important; }
  .mt-md-100,
  .my-md-100 {
    margin-top: 6.25rem !important; }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 6.25rem !important; }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 6.25rem !important; }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 6.25rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-10 {
    padding: 0.625rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 0.625rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 0.625rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 0.625rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 0.625rem !important; }
  .p-md-15 {
    padding: 0.9375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 0.9375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 0.9375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 0.9375rem !important; }
  .p-md-20 {
    padding: 1.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.25rem !important; }
  .p-md-25 {
    padding: 1.5625rem !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 1.5625rem !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 1.5625rem !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 1.5625rem !important; }
  .p-md-30 {
    padding: 1.875rem !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 1.875rem !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 1.875rem !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 1.875rem !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 1.875rem !important; }
  .p-md-40 {
    padding: 2.5rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 2.5rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 2.5rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 2.5rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 2.5rem !important; }
  .p-md-50 {
    padding: 3.125rem !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 3.125rem !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 3.125rem !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 3.125rem !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 3.125rem !important; }
  .p-md-60 {
    padding: 3.75rem !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 3.75rem !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 3.75rem !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 3.75rem !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 3.75rem !important; }
  .p-md-80 {
    padding: 5rem !important; }
  .pt-md-80,
  .py-md-80 {
    padding-top: 5rem !important; }
  .pr-md-80,
  .px-md-80 {
    padding-right: 5rem !important; }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 5rem !important; }
  .pl-md-80,
  .px-md-80 {
    padding-left: 5rem !important; }
  .p-md-90 {
    padding: 5.625rem !important; }
  .pt-md-90,
  .py-md-90 {
    padding-top: 5.625rem !important; }
  .pr-md-90,
  .px-md-90 {
    padding-right: 5.625rem !important; }
  .pb-md-90,
  .py-md-90 {
    padding-bottom: 5.625rem !important; }
  .pl-md-90,
  .px-md-90 {
    padding-left: 5.625rem !important; }
  .p-md-100 {
    padding: 6.25rem !important; }
  .pt-md-100,
  .py-md-100 {
    padding-top: 6.25rem !important; }
  .pr-md-100,
  .px-md-100 {
    padding-right: 6.25rem !important; }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 6.25rem !important; }
  .pl-md-100,
  .px-md-100 {
    padding-left: 6.25rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n10 {
    margin: -0.625rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -0.625rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -0.625rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -0.625rem !important; }
  .m-md-n15 {
    margin: -0.9375rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -0.9375rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -0.9375rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -0.9375rem !important; }
  .m-md-n20 {
    margin: -1.25rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -1.25rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -1.25rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -1.25rem !important; }
  .m-md-n25 {
    margin: -1.5625rem !important; }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -1.5625rem !important; }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -1.5625rem !important; }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -1.5625rem !important; }
  .m-md-n30 {
    margin: -1.875rem !important; }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -1.875rem !important; }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -1.875rem !important; }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -1.875rem !important; }
  .m-md-n40 {
    margin: -2.5rem !important; }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -2.5rem !important; }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -2.5rem !important; }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -2.5rem !important; }
  .m-md-n50 {
    margin: -3.125rem !important; }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -3.125rem !important; }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -3.125rem !important; }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -3.125rem !important; }
  .m-md-n60 {
    margin: -3.75rem !important; }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -3.75rem !important; }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -3.75rem !important; }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -3.75rem !important; }
  .m-md-n80 {
    margin: -5rem !important; }
  .mt-md-n80,
  .my-md-n80 {
    margin-top: -5rem !important; }
  .mr-md-n80,
  .mx-md-n80 {
    margin-right: -5rem !important; }
  .mb-md-n80,
  .my-md-n80 {
    margin-bottom: -5rem !important; }
  .ml-md-n80,
  .mx-md-n80 {
    margin-left: -5rem !important; }
  .m-md-n90 {
    margin: -5.625rem !important; }
  .mt-md-n90,
  .my-md-n90 {
    margin-top: -5.625rem !important; }
  .mr-md-n90,
  .mx-md-n90 {
    margin-right: -5.625rem !important; }
  .mb-md-n90,
  .my-md-n90 {
    margin-bottom: -5.625rem !important; }
  .ml-md-n90,
  .mx-md-n90 {
    margin-left: -5.625rem !important; }
  .m-md-n100 {
    margin: -6.25rem !important; }
  .mt-md-n100,
  .my-md-n100 {
    margin-top: -6.25rem !important; }
  .mr-md-n100,
  .mx-md-n100 {
    margin-right: -6.25rem !important; }
  .mb-md-n100,
  .my-md-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n100,
  .mx-md-n100 {
    margin-left: -6.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-10 {
    margin: 0.625rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 0.625rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 0.625rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 0.625rem !important; }
  .m-lg-15 {
    margin: 0.9375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 0.9375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 0.9375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 0.9375rem !important; }
  .m-lg-20 {
    margin: 1.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.25rem !important; }
  .m-lg-25 {
    margin: 1.5625rem !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 1.5625rem !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 1.5625rem !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 1.5625rem !important; }
  .m-lg-30 {
    margin: 1.875rem !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 1.875rem !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 1.875rem !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 1.875rem !important; }
  .m-lg-40 {
    margin: 2.5rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 2.5rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 2.5rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 2.5rem !important; }
  .m-lg-50 {
    margin: 3.125rem !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 3.125rem !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 3.125rem !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 3.125rem !important; }
  .m-lg-60 {
    margin: 3.75rem !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 3.75rem !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 3.75rem !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 3.75rem !important; }
  .m-lg-80 {
    margin: 5rem !important; }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 5rem !important; }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 5rem !important; }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 5rem !important; }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 5rem !important; }
  .m-lg-90 {
    margin: 5.625rem !important; }
  .mt-lg-90,
  .my-lg-90 {
    margin-top: 5.625rem !important; }
  .mr-lg-90,
  .mx-lg-90 {
    margin-right: 5.625rem !important; }
  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 5.625rem !important; }
  .ml-lg-90,
  .mx-lg-90 {
    margin-left: 5.625rem !important; }
  .m-lg-100 {
    margin: 6.25rem !important; }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 6.25rem !important; }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 6.25rem !important; }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 6.25rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-10 {
    padding: 0.625rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 0.625rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 0.625rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 0.625rem !important; }
  .p-lg-15 {
    padding: 0.9375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 0.9375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 0.9375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 0.9375rem !important; }
  .p-lg-20 {
    padding: 1.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.25rem !important; }
  .p-lg-25 {
    padding: 1.5625rem !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 1.5625rem !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 1.5625rem !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 1.5625rem !important; }
  .p-lg-30 {
    padding: 1.875rem !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 1.875rem !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 1.875rem !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 1.875rem !important; }
  .p-lg-40 {
    padding: 2.5rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 2.5rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 2.5rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 2.5rem !important; }
  .p-lg-50 {
    padding: 3.125rem !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 3.125rem !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 3.125rem !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 3.125rem !important; }
  .p-lg-60 {
    padding: 3.75rem !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 3.75rem !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 3.75rem !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 3.75rem !important; }
  .p-lg-80 {
    padding: 5rem !important; }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 5rem !important; }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 5rem !important; }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 5rem !important; }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 5rem !important; }
  .p-lg-90 {
    padding: 5.625rem !important; }
  .pt-lg-90,
  .py-lg-90 {
    padding-top: 5.625rem !important; }
  .pr-lg-90,
  .px-lg-90 {
    padding-right: 5.625rem !important; }
  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 5.625rem !important; }
  .pl-lg-90,
  .px-lg-90 {
    padding-left: 5.625rem !important; }
  .p-lg-100 {
    padding: 6.25rem !important; }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 6.25rem !important; }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 6.25rem !important; }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 6.25rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n10 {
    margin: -0.625rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -0.625rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -0.625rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -0.625rem !important; }
  .m-lg-n15 {
    margin: -0.9375rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -0.9375rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -0.9375rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -0.9375rem !important; }
  .m-lg-n20 {
    margin: -1.25rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -1.25rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -1.25rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -1.25rem !important; }
  .m-lg-n25 {
    margin: -1.5625rem !important; }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -1.5625rem !important; }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -1.5625rem !important; }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -1.5625rem !important; }
  .m-lg-n30 {
    margin: -1.875rem !important; }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -1.875rem !important; }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -1.875rem !important; }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -1.875rem !important; }
  .m-lg-n40 {
    margin: -2.5rem !important; }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -2.5rem !important; }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -2.5rem !important; }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -2.5rem !important; }
  .m-lg-n50 {
    margin: -3.125rem !important; }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -3.125rem !important; }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -3.125rem !important; }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -3.125rem !important; }
  .m-lg-n60 {
    margin: -3.75rem !important; }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -3.75rem !important; }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -3.75rem !important; }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -3.75rem !important; }
  .m-lg-n80 {
    margin: -5rem !important; }
  .mt-lg-n80,
  .my-lg-n80 {
    margin-top: -5rem !important; }
  .mr-lg-n80,
  .mx-lg-n80 {
    margin-right: -5rem !important; }
  .mb-lg-n80,
  .my-lg-n80 {
    margin-bottom: -5rem !important; }
  .ml-lg-n80,
  .mx-lg-n80 {
    margin-left: -5rem !important; }
  .m-lg-n90 {
    margin: -5.625rem !important; }
  .mt-lg-n90,
  .my-lg-n90 {
    margin-top: -5.625rem !important; }
  .mr-lg-n90,
  .mx-lg-n90 {
    margin-right: -5.625rem !important; }
  .mb-lg-n90,
  .my-lg-n90 {
    margin-bottom: -5.625rem !important; }
  .ml-lg-n90,
  .mx-lg-n90 {
    margin-left: -5.625rem !important; }
  .m-lg-n100 {
    margin: -6.25rem !important; }
  .mt-lg-n100,
  .my-lg-n100 {
    margin-top: -6.25rem !important; }
  .mr-lg-n100,
  .mx-lg-n100 {
    margin-right: -6.25rem !important; }
  .mb-lg-n100,
  .my-lg-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n100,
  .mx-lg-n100 {
    margin-left: -6.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-10 {
    margin: 0.625rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 0.625rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 0.625rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 0.625rem !important; }
  .m-xl-15 {
    margin: 0.9375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 0.9375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 0.9375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 0.9375rem !important; }
  .m-xl-20 {
    margin: 1.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.25rem !important; }
  .m-xl-25 {
    margin: 1.5625rem !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 1.5625rem !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 1.5625rem !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 1.5625rem !important; }
  .m-xl-30 {
    margin: 1.875rem !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 1.875rem !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 1.875rem !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 1.875rem !important; }
  .m-xl-40 {
    margin: 2.5rem !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 2.5rem !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 2.5rem !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 2.5rem !important; }
  .m-xl-50 {
    margin: 3.125rem !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 3.125rem !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 3.125rem !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 3.125rem !important; }
  .m-xl-60 {
    margin: 3.75rem !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 3.75rem !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 3.75rem !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 3.75rem !important; }
  .m-xl-80 {
    margin: 5rem !important; }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 5rem !important; }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 5rem !important; }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 5rem !important; }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 5rem !important; }
  .m-xl-90 {
    margin: 5.625rem !important; }
  .mt-xl-90,
  .my-xl-90 {
    margin-top: 5.625rem !important; }
  .mr-xl-90,
  .mx-xl-90 {
    margin-right: 5.625rem !important; }
  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 5.625rem !important; }
  .ml-xl-90,
  .mx-xl-90 {
    margin-left: 5.625rem !important; }
  .m-xl-100 {
    margin: 6.25rem !important; }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 6.25rem !important; }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 6.25rem !important; }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 6.25rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-10 {
    padding: 0.625rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 0.625rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 0.625rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 0.625rem !important; }
  .p-xl-15 {
    padding: 0.9375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 0.9375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 0.9375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 0.9375rem !important; }
  .p-xl-20 {
    padding: 1.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.25rem !important; }
  .p-xl-25 {
    padding: 1.5625rem !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 1.5625rem !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 1.5625rem !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 1.5625rem !important; }
  .p-xl-30 {
    padding: 1.875rem !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 1.875rem !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 1.875rem !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 1.875rem !important; }
  .p-xl-40 {
    padding: 2.5rem !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 2.5rem !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 2.5rem !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 2.5rem !important; }
  .p-xl-50 {
    padding: 3.125rem !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 3.125rem !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 3.125rem !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 3.125rem !important; }
  .p-xl-60 {
    padding: 3.75rem !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 3.75rem !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 3.75rem !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 3.75rem !important; }
  .p-xl-80 {
    padding: 5rem !important; }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 5rem !important; }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 5rem !important; }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 5rem !important; }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 5rem !important; }
  .p-xl-90 {
    padding: 5.625rem !important; }
  .pt-xl-90,
  .py-xl-90 {
    padding-top: 5.625rem !important; }
  .pr-xl-90,
  .px-xl-90 {
    padding-right: 5.625rem !important; }
  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 5.625rem !important; }
  .pl-xl-90,
  .px-xl-90 {
    padding-left: 5.625rem !important; }
  .p-xl-100 {
    padding: 6.25rem !important; }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 6.25rem !important; }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 6.25rem !important; }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 6.25rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n10 {
    margin: -0.625rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -0.625rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -0.625rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -0.625rem !important; }
  .m-xl-n15 {
    margin: -0.9375rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -0.9375rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -0.9375rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -0.9375rem !important; }
  .m-xl-n20 {
    margin: -1.25rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -1.25rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -1.25rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -1.25rem !important; }
  .m-xl-n25 {
    margin: -1.5625rem !important; }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -1.5625rem !important; }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -1.5625rem !important; }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -1.5625rem !important; }
  .m-xl-n30 {
    margin: -1.875rem !important; }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -1.875rem !important; }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -1.875rem !important; }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -1.875rem !important; }
  .m-xl-n40 {
    margin: -2.5rem !important; }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -2.5rem !important; }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -2.5rem !important; }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -2.5rem !important; }
  .m-xl-n50 {
    margin: -3.125rem !important; }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -3.125rem !important; }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -3.125rem !important; }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -3.125rem !important; }
  .m-xl-n60 {
    margin: -3.75rem !important; }
  .mt-xl-n60,
  .my-xl-n60 {
    margin-top: -3.75rem !important; }
  .mr-xl-n60,
  .mx-xl-n60 {
    margin-right: -3.75rem !important; }
  .mb-xl-n60,
  .my-xl-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n60,
  .mx-xl-n60 {
    margin-left: -3.75rem !important; }
  .m-xl-n80 {
    margin: -5rem !important; }
  .mt-xl-n80,
  .my-xl-n80 {
    margin-top: -5rem !important; }
  .mr-xl-n80,
  .mx-xl-n80 {
    margin-right: -5rem !important; }
  .mb-xl-n80,
  .my-xl-n80 {
    margin-bottom: -5rem !important; }
  .ml-xl-n80,
  .mx-xl-n80 {
    margin-left: -5rem !important; }
  .m-xl-n90 {
    margin: -5.625rem !important; }
  .mt-xl-n90,
  .my-xl-n90 {
    margin-top: -5.625rem !important; }
  .mr-xl-n90,
  .mx-xl-n90 {
    margin-right: -5.625rem !important; }
  .mb-xl-n90,
  .my-xl-n90 {
    margin-bottom: -5.625rem !important; }
  .ml-xl-n90,
  .mx-xl-n90 {
    margin-left: -5.625rem !important; }
  .m-xl-n100 {
    margin: -6.25rem !important; }
  .mt-xl-n100,
  .my-xl-n100 {
    margin-top: -6.25rem !important; }
  .mr-xl-n100,
  .mx-xl-n100 {
    margin-right: -6.25rem !important; }
  .mb-xl-n100,
  .my-xl-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n100,
  .mx-xl-n100 {
    margin-left: -6.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #f46a57 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #ef2b10 !important; }

.text-secondary {
  color: #6a7d90 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #4a5764 !important; }

.text-success {
  color: #3cba58 !important; }

a.text-success:hover, a.text-success:focus {
  color: #29803d !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #f46a57 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ef2b10 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #2f3e4c !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #12171d !important; }

.text-brown {
  color: #b18248 !important; }

a.text-brown:hover, a.text-brown:focus {
  color: #7b5a32 !important; }

.text-body {
  color: #2f3e4c !important; }

.text-muted {
  color: #6a7d90 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.btn {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: normal; }
  .btn-s32 {
    height: 32px; }
  .btn-s40 {
    height: 40px; }
  .btn-s50 {
    font-size: 1rem;
    height: 50px; }
  .btn-s60 {
    font-size: 1rem;
    height: 60px; }
  .btn.btn-outline-brown {
    color: #b18248; }
    .btn.btn-outline-brown:hover {
      color: #fff; }

label.custom-control-label {
  padding-top: 2px;
  font-size: 0.875rem; }

/**
 * Base
 */
/* Include fonts
   @mixin ======================================================================== */
/**
 * Roboto
 */
body {
  background: #fff url("../images/neva_river.jpg") no-repeat fixed center top/cover; }
  @media (max-width: 991.98px) {
    body {
      padding-top: 80px; } }

/* Titles
   @mixin ======================================================================== */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
  margin: 0 0 20px;
  line-height: 1.2;
  font-weight: 700; }
  h1:not(:first-child), .h1:not(:first-child),
  h2:not(:first-child), .h2:not(:first-child),
  h3:not(:first-child), .h3:not(:first-child),
  h4:not(:first-child), .h4:not(:first-child),
  h5:not(:first-child), .h5:not(:first-child),
  h6 .h6:not(:first-child) {
    margin-top: 20px; }

/**
 * Responsive titles
 */
h1, .h1 {
  /* 36px - 24px */ }
  @media (max-width: 320px) {
    h1, .h1 {
      font-size: 1.375rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 1.875rem; } }
  @media (max-width: 1199.98px) {
    h1, .h1 {
      font-size: calc(1.375rem + 8 * (100vw - 20rem) / 790); } }

h2, .h2 {
  /* 30px - 22px */ }
  @media (max-width: 320px) {
    h2, .h2 {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.75rem; } }
  @media (max-width: 1199.98px) {
    h2, .h2 {
      font-size: calc(1.25rem + 8 * (100vw - 20rem) / 790); } }

h3, .h3 {
  /* 26px - 20px */ }
  @media (max-width: 320px) {
    h3, .h3 {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.625rem; } }
  @media (max-width: 1199.98px) {
    h3, .h3 {
      font-size: calc(1.125rem + 8 * (100vw - 20rem) / 790); } }

h4, .h4 {
  /* 24px - 18px */ }
  @media (max-width: 320px) {
    h4, .h4 {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }
  @media (max-width: 1199.98px) {
    h4, .h4 {
      font-size: calc(1rem + 8 * (100vw - 20rem) / 790); } }

h5, .h5 {
  /* 20px - 17px */ }
  @media (max-width: 320px) {
    h5, .h5 {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    h5, .h5 {
      font-size: 1.25rem; } }
  @media (max-width: 1199.98px) {
    h5, .h5 {
      font-size: calc(1rem + 4 * (100vw - 20rem) / 790); } }

h6, .h6 {
  /* 18px - 16px */ }
  @media (max-width: 320px) {
    h6, .h6 {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    h6, .h6 {
      font-size: 1.125rem; } }
  @media (max-width: 1199.98px) {
    h6, .h6 {
      font-size: calc(1rem + 2 * (100vw - 20rem) / 790); } }

/**
 * Lists
 */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

/**
 * Font size
 * Font weight
 */
.fz-14 {
  font-size: 0.875rem; }

.fz-18 {
  font-size: 1.125rem; }

.fw-500 {
  font-weight: 500; }

.icon {
  display: inline-block; }

.icon_card {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 50px;
  height: 30px; }

.icon_cardmir {
  background-image: url(../images/sprite.png);
  background-position: -55px 0px;
  width: 50px;
  height: 30px; }

.icon_mastercard {
  background-image: url(../images/sprite.png);
  background-position: 0px -35px;
  width: 50px;
  height: 30px; }

.icon_visacard {
  background-image: url(../images/sprite.png);
  background-position: -55px -35px;
  width: 50px;
  height: 30px; }

.youtube {
  background-image: url(../images/sprite.png);
  background-position: 0px -70px;
  width: 24px;
  height: 24px; }

/**
 * Static blocks
 */
.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  min-width: 320px;
  max-width: 1920px;
  transition: margin .2s ease;
  position: relative;
  height: 100vh; }
  .wrapper__content {
    flex: 1; }

.header {
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1012;
  height: 55px; }
  @media (max-width: 991.98px) {
    .header {
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25); } }
  @media (min-width: 768px) {
    .header {
      padding-top: 10px;
      padding-bottom: 10px;
      height: auto; } }
  @media (min-width: 992px) {
    .header {
      position: static; } }
  .header__top {
    padding-bottom: 10px; }
    .header__top > .container {
      align-items: center;
      display: flex;
      justify-content: flex-end; }
  .header__bottom > .container {
    position: relative; }
  .header__bottom-middle {
    align-items: center;
    display: flex; }
  .header__bottom-right {
    align-items: center;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 991.98px) {
      .header__bottom-right a {
        color: #2f3e4c;
        display: block; }
        .header__bottom-right a .icon {
          font-size: 1.5625rem; } }
  .header__phone {
    align-items: flex-end;
    display: flex;
    flex-shrink: 0; }
    .header__phone-small {
      font-size: 0.75rem;
      margin-bottom: 0;
      padding-left: 10px; }
    .header__phone-icon {
      margin-right: 10px; }
      .header__phone-icon .icon {
        color: #2f3e4c;
        font-size: 2.0625rem; }
    .header__phone-tel {
      font-size: 1.25rem;
      font-weight: 500; }
      .header__phone-tel-link {
        color: #2f3e4c; }
        .header__phone-tel-link:hover {
          color: #f46a57;
          text-decoration: none; }
      .header__phone-tel .icon-whatsapp {
        font-size: 1.125rem;
        fill: #3cba58;
        margin-left: 10px; }

.logo__link {
  color: #2f3e4c;
  font-size: 1.875rem;
  line-height: 1.2;
  font-weight: 500; }
  .logo__link:hover {
    color: #f46a57;
    text-decoration: none; }

.logo__slogan {
  color: #2f3e4c;
  font-size: 0.9375rem; }

.logo--mobile .logo__link {
  font-size: 1.5rem; }

.logo--mobile .logo__slogan {
  font-size: 0.75rem; }

.mainnav {
  /**
     * All viewports
     */
  /**
     * Mobile
     */
  /**
     * Desktop
     */ }
  .mainnav__list {
    font-size: 0.875rem;
    font-weight: 500; }
    .mainnav__list li.is-active > a:not(:hover),
    .mainnav__list li.is-active > span:not(:hover), .mainnav__list li.active > a:not(:hover),
    .mainnav__list li.active > span:not(:hover), .mainnav__list li.current > a:not(:hover),
    .mainnav__list li.current > span:not(:hover) {
      color: #71d187; }
  @media (max-width: 991.98px) {
    .mainnav {
      background-color: #fff;
      position: fixed;
      top: 55px;
      left: 0;
      bottom: 0;
      overflow-y: auto;
      padding: 15px 5px;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
      width: 260px;
      z-index: 1011; }
      .mainnav.is-active {
        transform: translateX(0);
        transition: transform 0.3s ease; }
      .mainnav__list {
        font-size: 1rem;
        line-height: normal;
        margin: 0 -20px; }
        .mainnav__list > li > a,
        .mainnav__list > li .nav-header {
          border-bottom: 1px solid #dee2e6;
          display: block;
          color: #2f3e4c;
          padding: 15px; }
          .mainnav__list > li > a:hover,
          .mainnav__list > li .nav-header:hover {
            text-decoration: none; }
        .mainnav__list > li img {
          display: none; }
        .mainnav__list > li.parent > a,
        .mainnav__list > li.parent > .nav-header {
          align-items: center;
          display: flex;
          justify-content: space-between; }
          .mainnav__list > li.parent > a::after,
          .mainnav__list > li.parent > .nav-header::after {
            border-top: 1px solid #adb5bd;
            border-right: 1px solid #adb5bd;
            content: "";
            display: block;
            margin-left: 10px;
            transform: rotate(135deg);
            transition: transform 0.2s ease;
            height: 6px;
            width: 6px; }
        .mainnav__list > li.parent.is-active > a::after,
        .mainnav__list > li.parent.is-active > .nav-header::after, .mainnav__list > li.parent.active > a::after,
        .mainnav__list > li.parent.active > .nav-header::after, .mainnav__list > li.parent.open-child > a::after,
        .mainnav__list > li.parent.open-child > .nav-header::after {
          transform: rotate(-45deg);
          transition: transform 0.2s ease; }
        .mainnav__list > li.parent.close-child > a::after,
        .mainnav__list > li.parent.close-child > .nav-header::after {
          transform: rotate(135deg) !important;
          transition: transform 0.2s ease; }
      .mainnav__submenu,
      .mainnav .nav-child {
        font-size: 0.9375rem;
        background-color: #f8f9fa;
        padding: 20px; }
        .mainnav__submenu li a,
        .mainnav .nav-child li a {
          color: #2f3e4c;
          padding-top: 10px;
          padding-bottom: 10px;
          display: block; } }
  @media (min-width: 992px) {
    .mainnav {
      background-color: #2f3e4c;
      background-image: -webkit-gradient(linear, to bottom, from(#57748e), to(#2f3e4c));
      background-image: -webkit-linear-gradient(to bottom, #57748e, #2f3e4c);
      background-image: -moz-linear-gradient(to bottom, #57748e, #2f3e4c);
      background-image: -ms-linear-gradient(to bottom, #57748e, #2f3e4c);
      background-image: -o-linear-gradient(to bottom, #57748e, #2f3e4c);
      background-image: linear-gradient(to bottom, #57748e, #2f3e4c);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#57748e', EndColorStr='#2f3e4c'); }
      .mainnav__list {
        display: flex;
        justify-content: center; }
        .mainnav__list > li > a {
          align-items: center;
          color: #fff;
          position: relative;
          display: flex;
          transition: background-color .2s ease;
          height: 44px;
          padding: 10px 15px;
          text-transform: uppercase; } }
      @media (min-width: 992px) and (min-width: 1200px) {
        .mainnav__list > li > a {
          padding: 10px 25px; } }
  @media (min-width: 992px) {
          .mainnav__list > li > a:hover {
            background-color: #F02E14;
            background-image: -webkit-gradient(linear, to bottom, from(#f46a57), to(#F02E14));
            background-image: -webkit-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: -moz-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: -ms-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: -o-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: linear-gradient(to bottom, #f46a57, #F02E14);
            filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#f46a57', EndColorStr='#F02E14');
            text-decoration: none;
            transition: background-color .2s ease; }
        .mainnav__list > li:not(:last-child) > a {
          position: relative; }
          .mainnav__list > li:not(:last-child) > a::after {
            border-right: 1px dotted rgba(255, 255, 255, 0.5);
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            right: 0;
            bottom: 10px;
            width: 2px; }
        .mainnav__list > li.parent {
          position: relative; }
          .mainnav__list > li.parent:hover .mainnav__submenu,
          .mainnav__list > li.parent:hover .nav-child {
            opacity: 1;
            visibility: visible;
            transition: all 0.2s ease;
            margin-top: 0; }
          .mainnav__list > li.parent:hover > a {
            background-color: #F02E14;
            background-image: -webkit-gradient(linear, to bottom, from(#f46a57), to(#F02E14));
            background-image: -webkit-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: -moz-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: -ms-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: -o-linear-gradient(to bottom, #f46a57, #F02E14);
            background-image: linear-gradient(to bottom, #f46a57, #F02E14);
            filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#f46a57', EndColorStr='#F02E14');
            text-decoration: none;
            transition: background-color .2s ease; }
      .mainnav__submenu,
      .mainnav .nav-child {
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-top: 3px solid #6a7d90;
        padding-top: 15px;
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-wrap: wrap;
        width: 320px;
        position: absolute;
        top: 44px;
        left: 0;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: normal;
        margin-top: 20px;
        transition: all 0.2s ease;
        z-index: 10;
        text-align: center; }
        .mainnav__submenu::before,
        .mainnav .nav-child::before {
          border-bottom: 8px solid #6a7d90;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          content: "";
          position: absolute;
          top: -11px;
          left: 35px;
          height: 0;
          width: 0; }
        .mainnav__submenu li,
        .mainnav .nav-child li {
          padding: 0 10px;
          margin: 0;
          width: 50%; }
          .mainnav__submenu li:not(:last-child),
          .mainnav .nav-child li:not(:last-child) {
            margin-bottom: 15px; }
          .mainnav__submenu li a,
          .mainnav .nav-child li a {
            color: #6a7d90;
            display: block; }
            .mainnav__submenu li a:hover,
            .mainnav .nav-child li a:hover {
              text-decoration: none;
              color: #f46a57; }
            .mainnav__submenu li a .image-title,
            .mainnav .nav-child li a .image-title {
              display: block;
              margin-top: 4px; } }

/**
 * Hamburger
 */
.hamburger {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  transition: transform .2s ease;
  justify-content: space-between;
  margin-right: 8px;
  height: 21px;
  width: 28px;
  z-index: 10; }
  .hamburger::before, .hamburger::after,
  .hamburger > span {
    background-color: #2f3e4c;
    border-radius: 3px;
    content: "";
    display: block;
    transition: transform .2s ease;
    height: 3px;
    width: 100%; }
  .hamburger::before, .hamburger::after {
    max-width: 80%; }
  .hamburger.is-active,
  .show .hamburger {
    transition: transform .3s ease; }
    .hamburger.is-active::before, .hamburger.is-active::after, .hamburger.is-active > span,
    .show .hamburger::before,
    .show .hamburger::after,
    .show .hamburger > span {
      width: 100%; }
    .hamburger.is-active span,
    .show .hamburger span {
      left: -50px;
      opacity: 0; }
    .hamburger.is-active::before, .hamburger.is-active::after,
    .show .hamburger::before,
    .show .hamburger::after {
      top: 50%;
      position: absolute; }
    .hamburger.is-active::after,
    .show .hamburger::after {
      transform: rotate(-45deg); }
    .hamburger.is-active::before,
    .show .hamburger::before {
      transform: rotate(45deg); }

.breadcrumbs {
  margin-bottom: 15px;
  position: relative;
  z-index: 1; }
  .breadcrumbs__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.4; }
    .breadcrumbs__list li a {
      color: #3a4145; }
    .breadcrumbs__list li.is-active {
      color: #f46a57; }
  .breadcrumbs__divider {
    color: #a7b6c0;
    display: inline-block;
    font-size: 1rem;
    margin: 0 8px; }

.content--left {
  position: relative;
  z-index: 1; }

@media (min-width: 992px) {
  .content--right, .content--middle {
    position: relative;
    left: 25%; }
  .content--middle + .sidebar--right {
    position: relative;
    left: 25%; } }

.sidebar--yellow-offset {
  position: relative;
  padding-top: 150px; }
  .sidebar--yellow-offset::before {
    background-color: rgba(255, 193, 7, 0.2);
    content: "";
    position: absolute;
    top: 0;
    left: -70px;
    right: 0;
    display: block;
    height: 100px;
    z-index: 0; }

@media (min-width: 992px) {
  .sidebar--left {
    position: relative;
    left: -75%; } }

.module:not(:last-child) {
  margin-bottom: 30px; }

.module__title {
  margin-bottom: 30px; }

.sidebar .module {
  border: 1px solid #ced4da;
  background-color: #fff;
  padding: 20px; }
  .sidebar .module .module__title {
    background-color: #495057;
    color: #fff;
    padding: 8px 20px;
    margin: -21px -21px 15px;
    font-size: 1.125rem;
    line-height: normal; }
    .sidebar .module .module__title--primary {
      background-color: #f02e14;
      background-image: -webkit-gradient(linear, to bottom, from(#f46a57), to(#f02e14));
      background-image: -webkit-linear-gradient(to bottom, #f46a57, #f02e14);
      background-image: -moz-linear-gradient(to bottom, #f46a57, #f02e14);
      background-image: -ms-linear-gradient(to bottom, #f46a57, #f02e14);
      background-image: -o-linear-gradient(to bottom, #f46a57, #f02e14);
      background-image: linear-gradient(to bottom, #f46a57, #f02e14);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#f46a57', EndColorStr='#f02e14'); }

.header__top .module {
  margin-bottom: 0; }
  .header__top .module:not(:first-child) {
    margin-left: 30px; }

.textblock ol,
.textblock ul {
  font-size: 1rem;
  margin: 20px 0 20px 50px;
  line-height: 1.4; }

.textblock li:not(:last-child) {
  margin-bottom: 5px; }

.textblock ol {
  list-style: decimal; }

.textblock ul {
  list-style: disc; }

.textblock p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; }
  .textblock p:not(:last-child) {
    margin-bottom: 15px; }
  .textblock p blockquote {
    border-left: 3px solid #007bff;
    padding-left: 30px;
    margin: 30px 0 30px 25px;
    font-size: 1rem;
    font-style: italic; }

.textblock img {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px; }

.pagination__list {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.9375rem;
  list-style: none; }
  @media (min-width: 768px) {
    .pagination__list {
      font-size: 1.125rem; } }
  .pagination__list li {
    margin: 0 4px; }
    @media (min-width: 768px) {
      .pagination__list li {
        margin: 0 8px; } }
    .pagination__list li a, .pagination__list li span {
      align-items: center;
      color: #2f3e4c;
      display: flex;
      text-decoration: none;
      line-height: normal;
      padding: 0 8px;
      justify-content: center;
      height: 30px; }
      .pagination__list li a:hover, .pagination__list li span:hover {
        color: #f46a57;
        text-decoration: none; }
    .pagination__list li.is-disabled {
      display: none; }
    .pagination__list li.is-active a, .pagination__list li.is-active span {
      border: 1px solid #adb5bd;
      border-radius: 3px;
      color: #adb5bd;
      font-weight: 700; }

.pagination__result-counter {
  color: #adb5bd;
  font-size: 0.875rem;
  margin-top: 10px;
  text-align: center; }

.footer {
  margin-top: 30px;
  /**
     * Top
     */
  /**
     * Bottom
     */ }
  .footer__top {
    background-color: #2f3e4c;
    background-image: -webkit-gradient(linear, to bottom, from(#57748e), to(#2f3e4c));
    background-image: -webkit-linear-gradient(to bottom, #57748e, #2f3e4c);
    background-image: -moz-linear-gradient(to bottom, #57748e, #2f3e4c);
    background-image: -ms-linear-gradient(to bottom, #57748e, #2f3e4c);
    background-image: -o-linear-gradient(to bottom, #57748e, #2f3e4c);
    background-image: linear-gradient(to bottom, #57748e, #2f3e4c);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#57748e', EndColorStr='#2f3e4c');
    color: #fff;
    padding-top: 30px;
    padding-bottom: 15px; }
  .footer__logo .logo__link {
    font-size: 1.75rem;
    color: #ced4da; }
    .footer__logo .logo__link:hover {
      color: #fff; }
  .footer__logo .logo__slogan {
    color: #ced4da;
    font-size: 0.875rem; }
  .footer .module__title {
    color: #ced4da;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 20px; }
  .footer__nav-list {
    font-size: 0.875rem; }
    .footer__nav-list li:not(:last-child) {
      margin-bottom: 10px; }
    .footer__nav-list li a {
      color: #dee2e6; }
      .footer__nav-list li a:hover {
        color: #fff;
        text-decoration: none; }
    .footer__nav-list li.is-active a, .footer__nav-list li.active a {
      color: #3cba58; }
  .footer__phone {
    margin-top: 30px; }
    .footer__phone-link {
      font-size: 1.25rem;
      font-weight: 500;
      color: #ced4da; }
      .footer__phone-link:hover {
        color: #fff;
        text-decoration: none; }
    .footer__phone-city {
      color: #adb5bd;
      font-size: 0.875rem; }
  .footer__bottom {
    padding-top: 10px;
    padding-bottom: 10px; }

/**
 * Dev blocks
 */
.topmenu__list {
  align-items: center;
  display: flex;
  font-size: 0.875rem; }
  .topmenu__list li:not(:last-child) {
    margin-right: 15px; }
  .topmenu__list li a {
    color: #2f3e4c;
    text-decoration: underline; }
    .topmenu__list li a:hover {
      color: #f46a57;
      text-decoration: underline; }
  .topmenu__list li.active a {
    color: #3cba58; }

@media (min-width: 768px) {
  .m_search {
    width: 100%; } }

@media (max-width: 767.98px) {
  .m_search {
    background-color: #fff;
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 7px;
    z-index: 10;
    padding-left: 15px;
    padding-right: 15px; }
    .m_search.is-active {
      display: block; }
    .m_search__mobile-toggle {
      display: block;
      color: #2f3e4c; }
      .m_search__mobile-toggle .icon {
        font-size: 1.25rem;
        pointer-events: none; } }

.m_search__form {
  align-items: center;
  position: relative;
  display: flex; }
  .m_search__form .form-control {
    border-color: transparent;
    font-size: 0.875rem;
    height: 40px;
    padding-left: 15px;
    padding-right: 40px;
    transition: border-color .2s ease; }
    @media (min-width: 768px) {
      .m_search__form .form-control {
        background-color: #f8f9fa; } }
    .m_search__form .form-control::placeholder {
      color: #2f3e4c; }
    .m_search__form .form-control:focus {
      box-shadow: none; }
      @media (min-width: 992px) {
        .m_search__form .form-control:focus {
          border-color: #dee2e6;
          transition: border-color .2s ease; } }
  .m_search__form .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px; }
    .m_search__form .btn .icon {
      font-size: 1.125rem; }
    .m_search__form .btn:hover .icon {
      fill: #f46a57; }

.m_search__result {
  width: 100%; }

.m_cart__link {
  align-items: center;
  color: #6a7d90;
  display: inline-flex; }
  .m_cart__link:hover {
    text-decoration: none; }

.m_cart__left {
  display: block;
  position: relative; }
  .m_cart__left .icon {
    font-size: 1.875rem; }

.m_cart__count {
  background-color: #adb5bd;
  border-radius: 10px;
  color: #fff;
  padding: 0 7px;
  font-size: 0.625rem;
  height: 16px;
  position: absolute;
  top: -5px;
  right: -8px; }

.m_cart__right {
  display: none;
  margin-left: 15px; }
  @media (min-width: 992px) {
    .m_cart__right {
      display: block; } }

.m_cart__tlt {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  display: block; }

.m_cart__summ {
  font-size: 0.875rem;
  display: block; }

.m_cart.is-active .m_cart__link {
  color: #2f3e4c; }

.m_cart.is-active .m_cart__count {
  background-color: #3cba58; }

.brands-nav {
  /**
     * Mobile
     */
  /**
     * Desktop
     */ }
  .brands-nav__list li.parent > span {
    cursor: pointer; }
  .brands-nav__list li.active > a,
  .brands-nav__list li.active > span {
    color: #3cba58; }
  @media (max-width: 991.98px) {
    .brands-nav .parent .icon,
    .brands-nav .parent img {
      display: none; }
    .brands-nav .parent > a,
    .brands-nav .parent > span {
      align-items: center;
      border-bottom: 1px solid #dee2e6;
      display: flex;
      justify-content: space-between;
      padding: 15px; }
      .brands-nav .parent > a::after,
      .brands-nav .parent > span::after {
        border-top: 1px solid #adb5bd;
        border-right: 1px solid #adb5bd;
        content: "";
        display: block;
        margin-left: 10px;
        transform: rotate(135deg);
        transition: transform 0.2s ease;
        height: 6px;
        width: 6px; }
    .brands-nav .parent.is-active > a::after,
    .brands-nav .parent.is-active > span::after, .brands-nav .parent.open-child > a::after,
    .brands-nav .parent.open-child > span::after, .brands-nav .parent.active > a::after,
    .brands-nav .parent.active > span::after {
      transform: rotate(-45deg);
      transition: transform 0.2s ease; }
    .brands-nav__list {
      font-size: 1rem;
      font-weight: 500;
      line-height: normal;
      margin: 0 -20px; }
      .brands-nav__list li a,
      .brands-nav__list li span {
        color: #2f3e4c;
        padding: 15px;
        display: block; }
        .brands-nav__list li a:hover,
        .brands-nav__list li span:hover {
          text-decoration: none; }
        .brands-nav__list li a.image-title,
        .brands-nav__list li span.image-title {
          padding: 0; }
    .brands-nav__submenu,
    .brands-nav .nav-child {
      background-color: #f8f9fa;
      padding: 20px; }
      .brands-nav__submenu li a,
      .brands-nav__submenu li span,
      .brands-nav .nav-child li a,
      .brands-nav .nav-child li span {
        padding-top: 10px;
        padding-bottom: 10px; } }
  @media (min-width: 992px) {
    .brands-nav {
      background-color: #4f6880; }
      .brands-nav__list {
        font-size: 1rem; }
        .brands-nav__list > li > a,
        .brands-nav__list > li > span {
          padding: 10px 20px; }
        .brands-nav__list li a,
        .brands-nav__list li span {
          color: #fff; }
        .brands-nav__list li.parent a,
        .brands-nav__list li.parent span {
          align-items: center;
          display: flex; }
          .brands-nav__list li.parent a .icon,
          .brands-nav__list li.parent a img,
          .brands-nav__list li.parent span .icon,
          .brands-nav__list li.parent span img {
            margin-right: 10px; }
          .brands-nav__list li.parent a:hover,
          .brands-nav__list li.parent span:hover {
            color: #cbd7e2;
            text-decoration: none; }
        .brands-nav__list li.parent.active > a,
        .brands-nav__list li.parent.active span {
          color: #71d187; }
        .brands-nav__list li.parent:hover .brands-nav__submenu,
        .brands-nav__list li.parent:hover .nav-child {
          opacity: 1;
          visibility: visible;
          transition: all 0.2s ease;
          margin-top: 0; }
      .brands-nav__submenu,
      .brands-nav .nav-child {
        background-color: #4f6880;
        opacity: 0;
        visibility: hidden;
        padding: 0 20px 15px;
        width: 100%;
        position: absolute;
        top: 44px;
        left: 0;
        z-index: 10;
        font-size: 0.875rem;
        transition: all 0.2s ease;
        margin-top: 10px; }
        .brands-nav__submenu li a,
        .brands-nav__submenu li span,
        .brands-nav .nav-child li a,
        .brands-nav .nav-child li span {
          align-items: center;
          display: flex;
          padding: 7px 0; }
          .brands-nav__submenu li a::before,
          .brands-nav__submenu li span::before,
          .brands-nav .nav-child li a::before,
          .brands-nav .nav-child li span::before {
            border-left: 5px solid #fff;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            content: "";
            margin-right: 5px;
            height: 0;
            width: 0; }
          .brands-nav__submenu li a:hover::before,
          .brands-nav__submenu li span:hover::before,
          .brands-nav .nav-child li a:hover::before,
          .brands-nav .nav-child li span:hover::before {
            border-left-color: #cbd7e2; }
        .brands-nav__submenu li.active > a,
        .brands-nav__submenu li.active span,
        .brands-nav .nav-child li.active > a,
        .brands-nav .nav-child li.active span {
          color: #71d187; } }

.sb-menu__list {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: normal; }
  .sb-menu__list > li > a {
    color: #2f3e4c; }
    .sb-menu__list > li > a:hover {
      color: #f46a57;
      text-decoration: none; }
  .sb-menu__list > li:not(:last-child) {
    margin-bottom: 15px; }
  .sb-menu__list > li.is-active > a {
    color: #f46a57; }
  .sb-menu__list > li.parent > a {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .sb-menu__list > li.parent > a::after {
      border-top: 1px solid #2f3e4c;
      border-right: 1px solid #2f3e4c;
      content: "";
      display: block;
      margin-left: 10px;
      transform: rotate(135deg);
      transition: transform 0.2s ease;
      height: 6px;
      width: 6px; }
  .sb-menu__list > li.parent.is-active a::after, .sb-menu__list > li.parent.open-child a::after {
    transform: rotate(-45deg);
    transition: transform 0.2s ease; }
  .sb-menu__list > li.parent.close-child a::after {
    transform: rotate(135deg) !important;
    transition: transform 0.2s ease; }

.sb-menu__counter {
  align-items: center;
  background-color: #adb5bd;
  border-radius: 10px;
  color: #fff;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.625rem;
  line-height: normal;
  height: 15px;
  margin-left: 10px; }

.sb-menu__submenu {
  color: #bababa;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-left: 20px; }
  .sb-menu__submenu li:not(:last-child) {
    margin-bottom: 18px; }
  .sb-menu__submenu li a {
    align-items: center;
    display: inline-flex;
    color: #2f3e4c; }
    .sb-menu__submenu li a:hover {
      color: #f46a57;
      text-decoration: none; }
      .sb-menu__submenu li a:hover .sb-menu__counter {
        background-color: #f46a57; }
  .sb-menu__submenu li.is-active a {
    color: #3cba58; }

.article__image {
  margin-bottom: 20px; }

.article__info-block {
  color: #adb5bd;
  font-style: italic;
  font-size: 0.875rem;
  font-weight: 500;
  align-items: center;
  display: flex;
  margin-top: 55px; }
  .article__info-block .icon {
    margin-right: 5px;
    font-size: 1rem; }
  .article__info-block > div,
  .article__info-block > dd {
    align-items: center;
    display: flex; }
    .article__info-block > div:not(:last-child),
    .article__info-block > dd:not(:last-child) {
      margin-right: 30px; }

.list-articles__item:not(:last-child) {
  margin-bottom: 30px; }

.list-articles__title a {
  color: #2f3e4c; }
  .list-articles__title a:hover {
    text-decoration: none;
    color: #f46a57; }

.list-articles__date {
  font-size: 0.875rem; }

.list-articles__all {
  text-align: right;
  font-weight: 500;
  margin-top: 20px; }
  .list-articles__all a {
    color: #2f3e4c; }
    .list-articles__all a:hover {
      color: #f46a57;
      text-decoration: none; }

.payments-tlt {
  color: #adb5bd;
  font-size: 0.875rem;
  margin-bottom: 5px; }

.payments .icon:not(:last-child) {
  margin-right: 5px; }

.form label,
form label {
  font-size: 0.875rem;
  font-weight: 500; }

.form textarea.form-control,
form textarea.form-control {
  height: 120px; }

.form .fielderror,
form .fielderror {
  border-color: #f46a57; }

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  transition: all .3s ease;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010; }

body.animate .overlay {
  visibility: visible;
  opacity: 1;
  transition: all .3s ease; }

.m_lang-select {
  font-size: 0.875rem;
  line-height: normal; }
  .m_lang-select .lang-inline {
    align-items: center;
    display: flex; }
    .m_lang-select .lang-inline li:not(:last-child) {
      margin-right: 10px; }
    .m_lang-select .lang-inline li a {
      display: block; }

.alert {
  position: relative; }
  .alert .close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 1.5rem;
    color: #2f3e4c;
    line-height: 1;
    opacity: 0.9; }
    .alert .close:hover {
      text-decoration: none;
      opacity: 1; }

/**
 * Jshop
 */
#mxcpr {
  display: none; }

.stars_no_active {
  height: 16px;
  background-image: url(../images/star.gif);
  background-repeat: repeat-x;
  background-position: 0 0; }

.stars_active {
  height: 16px;
  background-image: url(../images/star.gif);
  background-repeat: repeat-x;
  background-position: 0 -16px; }

.field-calendar .input-append {
  align-items: center;
  display: flex; }

.field-calendar .btn {
  margin-left: 5px;
  height: 38px; }

.field-calendar .icon-calendar {
  background-image: url(../images/calendar.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  display: block;
  height: 18px;
  width: 18px; }

.star {
  color: #f46a57; }

.jshop-main-category {
  text-align: center; }
  .jshop-main-category__title {
    font-size: 1rem;
    font-weight: 500;
    padding: 10px;
    line-height: normal;
    margin-top: 10px; }
    .jshop-main-category__title a {
      color: #2f3e4c; }
      .jshop-main-category__title a:hover {
        color: #f46a57;
        text-decoration: none; }

.product {
  height: 100%;
  transition: border-color ease 0.3s; }
  .product > .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .product:hover {
    border-color: rgba(244, 106, 87, 0.5);
    transition: border-color ease 0.3s; }
  .product__image {
    position: relative;
    text-align: center;
    margin-bottom: 10px; }
    .product__image:hover .product__quickview-btn {
      transition: opacity 0.2s ease;
      opacity: 0.9; }
  .product__badge {
    position: absolute;
    top: 0;
    right: 0; }
  .product__label {
    position: absolute;
    top: 0;
    left: 0; }
  .product__header {
    margin-bottom: 15px; }
  .product__title {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal; }
    .product__title a {
      color: #2f3e4c; }
      .product__title a:hover {
        color: #f46a57;
        text-decoration: none; }
  .product__raiting {
    margin-bottom: 10px; }
  .product__price {
    color: #2f3e4c;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: normal;
    order: 2; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .product__price {
        margin-bottom: 10px;
        order: 1; } }
    .product__price-row {
      align-items: center;
      display: flex;
      flex-direction: column; }
      @media (min-width: 992px) {
        .product__price-row {
          align-items: flex-end;
          flex-direction: row; } }
    .product__price-curr,
    .product__price .currencycode {
      font-size: 0.875rem; }
  .product__shipping-txt {
    font-size: 0.75rem;
    order: 2;
    margin-left: 10px; }
  .product__oldprice {
    color: rgba(47, 62, 76, 0.3);
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: line-through;
    order: 1; }
    @media (min-width: 992px) {
      .product__oldprice {
        order: 2;
        margin-left: 10px; } }
  .product__extra_fields {
    margin-top: 10px; }
    .product__extra_fields .extra_fields__row {
      font-size: 0.75rem; }
  .product__btns {
    margin-top: 20px;
    display: flex;
    justify-content: center; }
    .product__btns .btn:not(:last-child) {
      margin-right: 6px; }
  .product__add-to-cart {
    align-items: center;
    color: #2f3e4c;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.0175rem;
    line-height: 1.2;
    display: inline-flex; }
    .product__add-to-cart .icon {
      margin-right: 8px; }
    .product__add-to-cart:hover {
      text-decoration: none;
      color: #f46a57; }
  .product__btn-addcart {
    height: 32px;
    width: 32px; }

.jshop-list-product__descr img {
  max-width: 100%;
  height: auto; }
  .jshop-list-product__descr img:not(:last-child) {
    margin-bottom: 15px; }

.extra_fields__row {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  line-height: normal; }
  .extra_fields__row:not(:last-child) {
    margin-bottom: 15px; }

.extra_fields__lbl {
  display: block;
  margin-right: 5px;
  font-weight: 700; }

.jshop-raiting {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  color: #6a7d90; }
  .jshop-raiting__comments {
    margin-left: 10px; }

.cart-table__title {
  align-items: center;
  display: flex; }
  .cart-table__title-count {
    align-items: center;
    background-color: #888;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 0.75rem;
    font-weight: 700;
    margin-left: 10px;
    justify-content: center;
    height: 25px;
    width: 25px; }

.cart-table__checkout {
  border-bottom: 1px solid #dee2e6; }

.cart-table .table {
  font-size: 0.875rem; }
  .cart-table .table th {
    color: #adb5bd;
    font-size: 0.875rem; }
  .cart-table .table th,
  .cart-table .table td {
    text-align: center;
    vertical-align: middle;
    line-height: 1.2; }
    .cart-table .table th.cart-table__product,
    .cart-table .table td.cart-table__product {
      text-align: left; }
    .cart-table .table th.product-name,
    .cart-table .table td.product-name {
      width: 45%; }
    .cart-table .table th.qty,
    .cart-table .table td.qty {
      width: 20%; }
  .cart-table .table tr:first-child th {
    border-top: none; }

.cart-table__image {
  padding-left: 10px; }
  .cart-table__image img {
    border-radius: 0.25rem;
    max-width: 70px;
    position: relative;
    z-index: 0; }

.cart-table__product-right {
  position: relative; }

.cart-table__product-name {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.2; }
  .cart-table__product-name a {
    color: #2f3e4c; }
    .cart-table__product-name a:hover {
      color: #f46a57;
      text-decoration: none; }

.cart-table .list_attribute,
.cart-table .list_free_attribute,
.cart-table .jshop-code-prod {
  font-size: 0.8125rem; }
  .cart-table .list_attribute p,
  .cart-table .list_free_attribute p,
  .cart-table .jshop-code-prod p {
    color: #adb5bd;
    font-size: 0.8125rem;
    margin-bottom: 4px; }

.cart-table__reload .icon {
  font-size: 0.875rem; }

.cart-table__summ {
  font-size: 0.875rem;
  font-weight: 700; }
  @media (min-width: 992px) {
    .cart-table__summ {
      font-size: 1.125rem;
      width: 15%; } }
  .cart-table__summ-curr,
  .cart-table__summ .currencycode {
    font-size: 0.875rem; }

.cart-table__remove a {
  color: #bebebe; }
  .cart-table__remove a .icon {
    font-size: 0.625rem; }
  .cart-table__remove a:hover {
    color: #f46a57; }

@media (min-width: 992px) {
  .cart-table__subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }

.cart-table__total {
  color: #adb5bd;
  font-size: 0.875rem; }
  .cart-table__total-row {
    display: flex;
    justify-content: space-between; }
    .cart-table__total-row:not(:last-child) {
      margin-bottom: 5px; }
    .cart-table__total-row--itog {
      color: #2f3e4c;
      font-size: 1.125rem;
      font-weight: 700; }
  .cart-table__total-summ {
    color: #2f3e4c;
    font-weight: 700; }

.cart-table__nav .btn .icon {
  font-size: 1.25rem; }

.cart-table__nav-prev .icon {
  margin-right: 8px; }

.cart-table__nav-next .icon {
  margin-left: 8px; }

.cart-table .jshop-prod-qty .form-control {
  max-width: 65px; }

.jshop-product-qty {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  display: flex;
  position: relative;
  height: 50px;
  padding: 9px;
  overflow: hidden;
  flex-shrink: 0;
  /**
     * Sizes
     */ }
  .jshop-product-qty__input {
    color: #2f3e4c;
    border: none;
    width: 100%;
    max-width: 45px;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    height: 100%; }
    .jshop-product-qty__input:focus {
      outline: none; }
  .jshop-product-qty__btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 16px; }
  .jshop-product-qty__btn {
    background-color: transparent;
    align-items: center;
    border: none;
    display: flex;
    height: 16px;
    width: 100%;
    justify-content: center; }
    .jshop-product-qty__btn::before {
      content: "";
      height: 0;
      width: 0; }
    .jshop-product-qty__btn:focus {
      outline: none; }
    .jshop-product-qty__btn:hover::before {
      border-top-color: #f46a57;
      border-bottom-color: #f46a57; }
    .jshop-product-qty__btn--plus::before {
      border-bottom: 4px solid #888;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent; }
    .jshop-product-qty__btn--minus::before {
      border-top: 4px solid #888;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent; }
  .jshop-product-qty--md {
    padding: 5px;
    height: 40px; }
  .jshop-product-qty .jshop-product-qty__input {
    max-width: 35px;
    font-size: 0.875rem; }
  .jshop-product-qty--sm {
    padding: 5px;
    height: 30px; }
    .jshop-product-qty--sm .jshop-product-qty__input {
      max-width: 28px;
      font-size: 0.875rem;
      height: 100% !important; }

.productfull {
  /**
     * Product image (Left)
     */
  /**
     * Right column
     */
  /**
     * Price
     */
  /**
     * Characteristics
     */
  /**
     * Buttons
     */ }
  .productfull__image {
    position: relative; }
    .productfull__image-link {
      display: block;
      position: relative;
      text-align: center; }
    .productfull__image-zoom {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 1.5rem; }
  .productfull__label {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10; }
  .productfull__img-thumbs {
    margin-top: 15px; }
    .productfull__img-thumbs > span {
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
    .productfull__img-thumbs .owl-stage-outer {
      padding-left: 5px;
      padding-right: 5px; }
    .productfull__img-thumbs .owl-item {
      border: 1px solid #ced4da; }
    .productfull__img-thumbs img {
      cursor: pointer;
      transition: border-color .3s ease; }
      .productfull__img-thumbs img:hover {
        border-color: #fabfb7;
        transition: border-color .3s ease; }
  .productfull__video-preview {
    position: relative;
    display: block; }
    .productfull__video-preview::before {
      background-image: url(../images/sprite.png);
      background-position: 0px -70px;
      width: 24px;
      height: 24px;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10; }
    .productfull__video-preview img {
      border: 1px solid #ced4da;
      padding: 1px; }
  .productfull__return {
    align-items: center;
    color: #6a7d90;
    display: inline-flex;
    font-size: 0.875rem; }
    .productfull__return--primary {
      color: #f46a57; }
    .productfull__return:hover {
      text-decoration: none; }
  .productfull__title {
    font-size: 1.5rem; }
  .productfull__instock {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 8px;
    padding-right: 8px;
    vertical-align: bottom;
    transform: translateY(-4px);
    margin-left: 10px; }
  .productfull__info {
    color: #adb5bd;
    font-size: 0.875rem; }
  .productfull__price-row {
    align-items: flex-end;
    display: flex; }
  .productfull__price {
    font-weight: 700;
    line-height: normal; }
    @media (max-width: 320px) {
      .productfull__price {
        font-size: 1.25rem; } }
    @media (min-width: 1200px) {
      .productfull__price {
        font-size: 1.875rem; } }
    @media (max-width: 1199.98px) {
      .productfull__price {
        font-size: calc(1.25rem + 10 * (100vw - 20rem) / 790); } }
    .productfull__price-curr,
    .productfull__price .currencycode {
      font-size: 1.125rem; }
  .productfull__old-price {
    color: rgba(47, 62, 76, 0.3);
    text-decoration: line-through;
    margin-left: 15px;
    line-height: normal;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 2px; }
  .productfull__charact {
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .productfull__charact {
        margin-top: 40px; } }
    .productfull__charact-tlt {
      color: #2f3e4c; }
    .productfull__charact-row {
      display: flex;
      font-size: 0.875rem;
      font-weight: 500;
      justify-content: space-between; }
      .productfull__charact-row:not(:last-child) {
        margin-bottom: 10px; }
      .productfull__charact-row-tlt {
        color: #adb5bd; }
      .productfull__charact-row-val {
        color: #6a7d90; }
  .productfull__btns {
    display: flex;
    margin-top: 30px; }
    .productfull__btns .btn {
      margin-right: 8px; }
      @media (max-width: 575.98px) {
        .productfull__btns .btn {
          width: 100%; } }
      .productfull__btns .btn .icon {
        font-size: 1.125rem; }
  .productfull__btn-favorit.btn {
    background-color: #dee2e6;
    width: 50px;
    flex-shrink: 0; }
    .productfull__btn-favorit.btn:hover {
      background-color: #c1c9d0; }

.quickview.mfp-white-block {
  max-width: 960px; }

.quickview .mfp-close {
  color: #dedede;
  font-size: 3.125rem; }
  @media (min-width: 992px) {
    .quickview .mfp-close {
      top: 10px;
      right: 10px; }
      .quickview .mfp-close:active {
        top: 10px;
        right: 10px; } }

.quickview .productfull__charact-row > span {
  background-color: #fff; }

.quickview .owl-prev .icon,
.quickview .owl-next .icon {
  font-size: 1.25rem !important; }

.jshop-register__tabs {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .jshop-register__tabs {
      margin-bottom: 30px; } }
  .jshop-register__tabs li {
    font-weight: 500; }
    @media (max-width: 320px) {
      .jshop-register__tabs li {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .jshop-register__tabs li {
        font-size: 2.25rem; } }
    @media (max-width: 1199.98px) {
      .jshop-register__tabs li {
        font-size: calc(1.5rem + 12 * (100vw - 20rem) / 790); } }
    .jshop-register__tabs li a {
      color: rgba(47, 62, 76, 0.2); }
      .jshop-register__tabs li a:hover {
        color: rgba(47, 62, 76, 0.4); }
      .jshop-register__tabs li a.active {
        color: #2f3e4c; }

.jshop-register #reg_test_password {
  display: block; }
  .jshop-register #reg_test_password:not(.fielderrormsg) {
    display: none; }

.profile__data {
  font-size: 1rem; }
  .profile__data-row {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
    .profile__data-row:not(:last-child) {
      margin-bottom: 10px; }
  .profile__data-name {
    font-weight: 500;
    margin-right: 5px; }

.profile__urls {
  margin-top: 20px; }
  .profile__urls > div:not(:last-child) {
    margin-bottom: 5px; }

.order {
  border: 1px solid #dedede;
  color: #495057;
  font-size: 1rem; }
  .order:not(:first-child) {
    margin-top: -1px; }
  .order__left {
    border-right: 1px solid #e8e8e8;
    padding: 15px;
    height: 100%; }
    @media (min-width: 992px) {
      .order__left {
        padding: 20px; } }
  .order__number {
    color: #007bff;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 5px; }
  .order__date {
    color: #495057;
    font-size: 0.875rem; }
  .order__middle {
    height: 100%; }
    .order__middle-top {
      border-bottom: 1px solid #e8e8e8;
      padding: 15px;
      line-height: 1.2; }
      @media (min-width: 992px) {
        .order__middle-top {
          padding: 15px 25px; } }
    .order__middle-bottom {
      padding: 10px; }
  .order__status {
    align-items: center;
    display: flex;
    font-size: 0.875rem; }
    .order__status::before {
      background-color: #e9ecef;
      content: "";
      border-radius: 50%;
      margin-right: 10px;
      height: 8px;
      width: 8px; }
    .order__status--processed::before {
      background-color: #ffc107; }
    .order__status--confirmed::before {
      background-color: #007bff; }
    .order__status--completed::before {
      background-color: #3cba58; }
  .order__product-collapse {
    border-bottom: 1px dashed #6a7d90;
    color: #6a7d90;
    text-decoration: none;
    font-weight: 500; }
    .order__product-collapse:hover {
      color: #495057;
      border-bottom-color: #495057;
      text-decoration: none; }
  .order__right {
    border-left: 1px solid #e8e8e8;
    padding: 10px 15px;
    height: 100%; }
    @media (min-width: 992px) {
      .order__right {
        padding: 10px 20px; } }
  .order__price > span {
    color: #495057; }
  .order__cancel-order {
    color: #f46a57;
    font-size: 0.875rem; }
    .order__cancel-order:hover {
      color: #495057; }
  .order .btn-danger:not(:hover) {
    background-color: rgba(244, 106, 87, 0.15);
    border-color: rgba(244, 106, 87, 0.15);
    color: #f46a57; }
  .order .btn-primary:not(:hover) {
    background-color: rgba(0, 123, 255, 0.15);
    border-color: rgba(0, 123, 255, 0.15);
    color: #007bff; }

.m_filter {
  margin: 0 -20px -20px; }
  .m_filter__block:not(:last-child) .m_filter__title {
    border-bottom: 1px solid #ced4da; }
  .m_filter__block.is-active .m_filter__title::after {
    transform: rotate(-45deg);
    transition: transform .2s ease; }
  .m_filter__block.is-active .m_filter__items {
    display: block; }
  .m_filter__title {
    background-color: #e9ecef;
    font-size: 0.875rem;
    padding: 10px 15px;
    color: #2f3e4c;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .m_filter__title::after {
      border-top: 1px solid #2f3e4c;
      border-right: 1px solid #2f3e4c;
      content: "";
      transform: rotate(135deg);
      height: 7px;
      width: 7px;
      transition: transform .2s ease; }
  .m_filter__items {
    display: none;
    padding: 10px;
    max-height: 150px;
    overflow-y: auto; }
  .m_filter .custom-control-label {
    font-weight: 400;
    line-height: normal; }

.checkout__header {
  border-bottom: 1px solid #ced4da;
  padding: 0 0 15px;
  margin: 0 0 20px; }

.checkout__step-tlt {
  font-size: 1.125rem;
  margin-bottom: 0; }

.checkout__body {
  font-size: 0.875rem; }

.checkout .form-control {
  font-size: 0.875rem; }

.checkout__code_prod {
  color: #adb5bd;
  margin-top: 3px; }

/**
 * Libs styles
 */
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 2.75rem;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 1.75rem;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 0.75rem;
  line-height: 1.125rem;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 0.75rem;
    line-height: 0.875rem; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 1.125rem;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 2.1875rem;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/** ------------------------------------------------------------
        =================== TRANSITIONS =======================
---------------------------------------------------------------------- **/
/**
 * Simple Scale transition,
 */
.mfp-scale.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
  transform: scale(1.3); }

.mfp-scale.mfp-bg.mfp-ready {
  opacity: 0.8;
  transform: scale(1); }

.mfp-scale.mfp-bg.mfp-removing {
  opacity: 0;
  transform: scale(1.3); }

.mfp-scale.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
  transform: scale(1.3); }

.mfp-scale.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.mfp-scale.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
  transform: scale(1.3); }

/**
 * Bottom Up transition
 */
.mfp-bottom-up.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-bottom-up.mfp-bg.mfp-ready {
  opacity: 0.8;
  transition: all 0.3s ease-out; }

.mfp-bottom-up.mfp-bg.mfp-removing {
  transition: all 0.3s ease-out;
  opacity: 0; }

.mfp-bottom-up.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  top: 100%; }

.mfp-bottom-up.mfp-wrap.mfp-ready .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 1;
  top: 0; }

.mfp-bottom-up.mfp-wrap.mfp-removing .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 0;
  top: 100%; }

/**
 * Top Down transition,
 */
.mfp-top-up.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-top-up.mfp-bg.mfp-ready {
  opacity: 0.8;
  transition: all 0.3s ease-out; }

.mfp-top-up.mfp-bg.mfp-removing {
  transition: all 0.3s ease-out;
  opacity: 0; }

.mfp-top-up.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  bottom: 100%; }

.mfp-top-up.mfp-wrap.mfp-ready .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 1;
  bottom: 0; }

.mfp-top-up.mfp-wrap.mfp-removing .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 0;
  bottom: 100%; }

.mfp-white-block {
  background-color: #fff;
  box-shadow: 0 0 60px 0 rgba(45, 49, 67, 0.2);
  max-width: 470px;
  width: 100%;
  position: relative;
  padding: 20px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 576px) {
    .mfp-white-block {
      padding: 30px; } }
  .mfp-white-block--960 {
    max-width: 960px; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
    display: flex;
    align-items: center; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav button[class*='owl-'] {
    background-color: #495057;
    color: #FFF;
    font-size: 0.875rem;
    margin: 0;
    padding: 4px !important;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .owl-theme .owl-nav button[class*='owl-']:hover:not(.disabled) {
      background-color: #f46a57;
      color: #FFF;
      text-decoration: none; }
    .owl-theme .owl-nav button[class*='owl-'] .icon {
      font-size: 1.25rem; }
      @media (min-width: 992px) {
        .owl-theme .owl-nav button[class*='owl-'] .icon {
          font-size: 1.5rem; } }
    .owl-theme .owl-nav button[class*='owl-']:focus {
      outline: none; }
    .owl-theme .owl-nav button[class*='owl-'].disabled {
      opacity: 0.5;
      cursor: default; }
  .owl-theme .owl-nav .owl-prev {
    left: -12px; }
  .owl-theme .owl-nav .owl-next {
    right: -12px; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  align-items: center;
  display: flex;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  margin-top: 20px; }
  .owl-theme .owl-dots .owl-dot {
    display: block; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot:not(:last-child) {
      margin-right: 5px 7px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

/**
 * Select
 */
.jq-selectbox {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
  letter-spacing: 0.0175rem;
  height: 38px;
  width: 100%; }
  .jq-selectbox.opened {
    border-color: #f46a57; }
    .jq-selectbox.opened .jq-selectbox__trigger-arrow {
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: all .2s ease; }
  .jq-selectbox__select {
    height: 100%;
    z-index: 1; }
    .jq-selectbox__select-text {
      align-items: center;
      color: #6a7d90;
      display: flex;
      font-size: 0.875rem;
      padding: 0 0 0 15px;
      margin-right: 30px;
      height: 100%; }
  .jq-selectbox__trigger {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px; }
    .jq-selectbox__trigger-arrow {
      border-right: 1px solid #000;
      border-top: 1px solid #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform: translate(-50%, -50%) rotate(135deg);
      transition: all .2s ease;
      height: 7px;
      width: 7px; }
  .jq-selectbox__dropdown {
    background-color: #fff;
    box-shadow: 4px 3px 25px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 0; }
    .jq-selectbox__dropdown ul {
      color: #6a7d90;
      font-size: 0.875rem;
      padding: 10px 0;
      margin: 0; }
      .jq-selectbox__dropdown ul li {
        padding: 5px 15px; }
        .jq-selectbox__dropdown ul li:hover {
          background-color: rgba(243, 144, 6, 0.1); }
        .jq-selectbox__dropdown ul li.selected {
          background-color: #6a7d90;
          color: #fff; }

/**
 * Checkbox
 */
.jq-checkbox {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 0.25rem;
  height: 22px;
  width: 22px; }
  .jq-checkbox__div {
    border-top: 2px solid #da8105;
    border-right: 2px solid #da8105;
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
    transform: rotate(135deg);
    height: 8px;
    width: 14px; }
  .jq-checkbox.checked .jq-checkbox__div {
    display: block; }

/**
 * Radio
 */
.jq-radio {
  border: 1px solid #d3d3d3;
  border-radius: 50%;
  position: relative;
  min-width: 16px;
  height: 16px;
  width: 16px; }
  .jq-radio.checked .jq-radio__div {
    background-color: #fd7e14;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 8px; }

/**
 * File
 */
/**
 * Variables declared here can be overridden by consuming applications, with
 * the help of the `!default` flag.
 *
 * @example
 *     // overriding $hoverColor
 *     $hoverColor: rgba(red, 0.05);
 *
 *     // overriding image path
 *     $flagsImagePath: "images/";
 *
 *     // import the scss file after the overrides
 *     @import "bower_component/intl-tel-input/src/css/intlTelInput";
 */
.intl-tel-input {
  position: relative;
  display: block; }

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.intl-tel-input .hide {
  display: none; }

.intl-tel-input .v-hide {
  visibility: hidden; }

.intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0; }

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px; }

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px; }

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto; }

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555; }

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555; }

.intl-tel-input .country-list {
  position: absolute;
  z-index: 1000;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll; }

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px; }

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px; }

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal; } }

.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC; }

.intl-tel-input .country-list .country {
  padding: 5px 10px; }

.intl-tel-input .country-list .country .dial-code {
  color: #999; }

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05); }

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
  vertical-align: middle; }

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
  margin-right: 6px; }

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=text], .intl-tel-input.separate-dial-code input[type=tel] {
  padding-right: 6px !important;
  padding-left: 52px !important;
  margin-left: 0; }

.intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
  right: auto;
  left: 0; }

.intl-tel-input.allow-dropdown .selected-flag, .intl-tel-input.separate-dial-code .selected-flag {
  width: 46px; }

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer; }

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default; }

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: transparent; }

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table; }

.intl-tel-input.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px; }

.intl-tel-input.separate-dial-code.iti-sdc-2 input, .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel] {
  padding-left: 66px; }

.intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 60px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
  padding-left: 76px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
  width: 70px; }

.intl-tel-input.separate-dial-code.iti-sdc-3 input, .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 74px; }

.intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 68px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
  padding-left: 84px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
  width: 78px; }

.intl-tel-input.separate-dial-code.iti-sdc-4 input, .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 82px; }

.intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 76px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
  padding-left: 92px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 86px; }

.intl-tel-input.separate-dial-code.iti-sdc-5 input, .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel] {
  padding-left: 90px; }

.intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 84px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
  padding-left: 100px; }

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
  width: 94px; }

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px; }

.intl-tel-input.iti-container:hover {
  cursor: pointer; }

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%; }

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em; }

.iti-flag {
  width: 20px; }

.iti-flag.be {
  width: 18px; }

.iti-flag.ch {
  width: 15px; }

.iti-flag.mc {
  width: 19px; }

.iti-flag.ne {
  width: 18px; }

.iti-flag.np {
  width: 13px; }

.iti-flag.va {
  width: 15px; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px; } }

.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px; }

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px; }

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px; }

.iti-flag.af {
  height: 14px;
  background-position: -66px 0px; }

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px; }

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px; }

.iti-flag.al {
  height: 15px;
  background-position: -132px 0px; }

.iti-flag.am {
  height: 10px;
  background-position: -154px 0px; }

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px; }

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px; }

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px; }

.iti-flag.as {
  height: 10px;
  background-position: -242px 0px; }

.iti-flag.at {
  height: 14px;
  background-position: -264px 0px; }

.iti-flag.au {
  height: 10px;
  background-position: -286px 0px; }

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px; }

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px; }

.iti-flag.az {
  height: 10px;
  background-position: -352px 0px; }

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px; }

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px; }

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px; }

.iti-flag.be {
  height: 15px;
  background-position: -440px 0px; }

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px; }

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px; }

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px; }

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px; }

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px; }

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px; }

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px; }

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px; }

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px; }

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px; }

.iti-flag.br {
  height: 14px;
  background-position: -680px 0px; }

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px; }

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px; }

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px; }

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px; }

.iti-flag.by {
  height: 10px;
  background-position: -790px 0px; }

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px; }

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px; }

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px; }

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px; }

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px; }

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px; }

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px; }

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px; }

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px; }

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px; }

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px; }

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px; }

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px; }

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px; }

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px; }

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px; }

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px; }

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px; }

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px; }

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0px; }

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px; }

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px; }

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px; }

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px; }

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px; }

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px; }

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px; }

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px; }

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px; }

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px; }

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px; }

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px; }

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px; }

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px; }

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px; }

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px; }

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px; }

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px; }

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px; }

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px; }

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px; }

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px; }

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px; }

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px; }

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px; }

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px; }

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px; }

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px; }

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px; }

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px; }

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px; }

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px; }

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px; }

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px; }

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px; }

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px; }

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px; }

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px; }

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px; }

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px; }

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px; }

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px; }

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px; }

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px; }

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px; }

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px; }

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px; }

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px; }

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px; }

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px; }

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px; }

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px; }

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px; }

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px; }

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px; }

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px; }

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px; }

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px; }

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px; }

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px; }

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px; }

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px; }

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px; }

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px; }

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px; }

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px; }

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px; }

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px; }

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px; }

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px; }

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px; }

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px; }

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px; }

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px; }

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px; }

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px; }

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px; }

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px; }

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px; }

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px; }

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px; }

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px; }

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px; }

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px; }

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px; }

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px; }

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px; }

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px; }

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px; }

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px; }

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px; }

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px; }

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px; }

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px; }

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px; }

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px; }

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px; }

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px; }

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px; }

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px; }

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px; }

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px; }

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px; }

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px; }

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px; }

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px; }

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px; }

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px; }

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px; }

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px; }

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px; }

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px; }

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px; }

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px; }

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px; }

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px; }

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px; }

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px; }

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px; }

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px; }

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px; }

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px; }

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px; }

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px; }

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px; }

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px; }

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px; }

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px; }

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px; }

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px; }

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px; }

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px; }

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px; }

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px; }

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px; }

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px; }

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px; }

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px; }

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px; }

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px; }

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px; }

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px; }

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px; }

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px; }

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px; }

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px; }

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px; }

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px; }

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px; }

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px; }

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px; }

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px; }

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px; }

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px; }

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px; }

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px; }

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px; }

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px; }

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px; }

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px; }

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px; }

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px; }

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px; }

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px; }

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px; }

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px; }

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px; }

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px; }

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px; }

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px; }

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px; }

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px; }

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px; }

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px; }

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px; }

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px; }

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px; }

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px; }

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px; }

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px; }

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px; }

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px; }

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px; }

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px; }

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px; }

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px; }

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px; }

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px; }

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px; }

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px; }

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px; }

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px; }

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px; }

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px; }

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px; }

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px; }

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px; }

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px; }

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px; }

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px; }

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../images/flags@2x.png"); } }

.iti-flag.np {
  background-color: transparent; }
