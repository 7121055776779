.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-arrow-left {    
    width:(24/24)*1em;
}
.icon-arrow-right {    
    width:(24/24)*1em;
}
.icon-arrow-up {    
    width:(32/32)*1em;
}
.icon-cancel {    
    width:(492/492)*1em;
}
.icon-cart {    
    width:(24/24)*1em;
}
.icon-eye {    
    width:(28/28)*1em;
}
.icon-heart {    
    width:(24/24)*1em;
}
.icon-icon_list {    
    width:(24/24)*1em;
}
.icon-phone-call {    
    width:(24/24)*1em;
}
.icon-placeholder {    
    width:(24/24)*1em;
}
.icon-refresh {    
    width:(512/512)*1em;
}
.icon-search {    
    width:(24/24)*1em;
}
.icon-shopping-cart {    
    width:(24/24)*1em;
}
.icon-star {    
    width:(24/24)*1em;
}
.icon-user {    
    width:(24/24)*1em;
}
.icon-whatsapp {    
    width:(512/512)*1em;
}
.icon-zoom-in {    
    width:(24/24)*1em;
}
