.brands-nav {
    $self: &;

    &__list {
        li {
            &.parent {
                > span {
                    cursor: pointer; } }

            &.active {
                > a,
                > span {
                    color: $green; } } } }

    /**
     * Mobile
     */
    @include media-breakpoint-down(md) {
        .parent {
            .icon,
            img {
                display: none; }

            > a,
            > span {
                align-items: center;
                border-bottom: 1px solid $gray-300;
                display: flex;
                justify-content: space-between;
                padding: 15px;

                &::after {
                    border-top: 1px solid $gray-500;
                    border-right: 1px solid $gray-500;
                    content: "";
                    display: block;
                    margin-left: 10px;
                    transform: rotate(135deg);
                    transition: transform 0.2s ease;
                    height: 6px;
                    width: 6px; } }

            &.is-active,
            &.open-child,
            &.active {
                > a,
                > span {
                    &::after {
                        transform: rotate(-45deg);
                        transition: transform 0.2s ease; } } } }
        &__list {
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            margin: 0 -20px;

            li {
                a,
                span {
                    color: $gray-800;
                    padding: 15px;
                    display: block;

                    &:hover {
                        text-decoration: none; }

                    &.image-title {
                        padding: 0; } } } }

        &__submenu,
        .nav-child {
            background-color: $gray-100;
            padding: 20px;

            li {
                a,
                span {
                    padding-top: 10px;
                    padding-bottom: 10px; } } } }


    /**
     * Desktop
     */
    @include media-breakpoint-up(lg) {
        background-color: #4f6880;

        &__list {
            font-size: 16px;

            > li {
                > a,
                > span {
                    padding: 10px 20px; } }

            li {
                a,
                span {
                    color: $white; }

                &.parent {
                    a,
                    span {
                        align-items: center;
                        display: flex;

                        .icon,
                        img {
                            margin-right: 10px; }

                        &:hover {
                            color: #cbd7e2;
                            text-decoration: none; } }

                    &.active {
                        > a,
                        span {
                            color: lighten($green, 15%); } }

                    &:hover {
                        #{$self}__submenu,
                        .nav-child {
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.2s ease;
                            margin-top: 0; } } } } }

        // Submenu
        &__submenu,
        .nav-child {
            background-color: #4f6880;
            opacity: 0;
            visibility: hidden;
            padding: 0 20px 15px;
            width: 100%;
            position: absolute;
            top: 44px;
            left: 0;
            z-index: 10;
            font-size: 14px;
            transition: all 0.2s ease;
            margin-top: 10px;

            li {
                a,
                span {
                    align-items: center;
                    display: flex;
                    padding: 7px 0;

                    &::before {
                        border-left: 5px solid $white;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        content: "";
                        margin-right: 5px;
                        height: 0;
                        width: 0; }

                    &:hover {
                        &::before {
                            border-left-color: #cbd7e2; } } }

                &.active {
                    > a,
                    span {
                        color: lighten($green, 15%); } } } } } }
