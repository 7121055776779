.m_filter {
    $self: &;

    margin: 0 -20px -20px;

    &__block {
        &:not(:last-child) {
            #{$self}__title {
                border-bottom: 1px solid $gray-400; } }

        // Active state
        &.is-active {
            #{$self} {
                &__title {
                    &::after {
                        transform: rotate(-45deg);
                        transition: transform .2s ease; } }

                &__items {
                    display: block; } } } }

    &__title {
        background-color: $gray-200;
        font-size: 14px;
        padding: 10px 15px;
        color: $dark;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
            border-top: 1px solid $dark;
            border-right: 1px solid $dark;
            content: "";
            transform: rotate(135deg);
            height: 7px;
            width: 7px;
            transition: transform .2s ease; } }

    &__items {
        display: none;
        padding: 10px;
        max-height: 150px;
        overflow-y: auto; }

    .custom-control-label {
        font-weight: 400;
        line-height: normal; } }
