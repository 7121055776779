@mixin hoverLink {
    @include linear-gradient(to bottom, #f46a57, #F02E14);
    text-decoration: none;
    transition: background-color .2s ease; }

.mainnav {
    $self: &;

    /**
     * All viewports
     */
    &__list {
        font-size: 14px;
        font-weight: 500;

        li {
            &.is-active,
            &.active,
            &.current {
                > a,
                > span {
                    &:not(:hover) {
                        color: lighten($green, 15%); } } } } }

    /**
     * Mobile
     */
    @include media-breakpoint-down(md) {
        background-color: $white;
        position: fixed;
        top: 55px;
        left: 0;
        bottom: 0;
        overflow-y: auto;
        padding: 15px 5px;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        width: 260px;
        z-index: 1011;

        &.is-active {
            transform: translateX(0);
            transition: transform 0.3s ease; }

        &__list {
            font-size: 16px;
            line-height: normal;
            margin: 0 -20px;

            > li {
                > a,
                .nav-header {
                    border-bottom: 1px solid $gray-300;
                    display: block;
                    color: $gray-800;
                    padding: 15px;
                    &:hover {
                        text-decoration: none; } }

                img {
                    display: none; }

                &.parent {
                    > a,
                    > .nav-header {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;

                        &::after {
                            border-top: 1px solid $gray-500;
                            border-right: 1px solid $gray-500;
                            content: "";
                            display: block;
                            margin-left: 10px;
                            transform: rotate(135deg);
                            transition: transform 0.2s ease;
                            height: 6px;
                            width: 6px; } }

                    &.is-active,
                    &.active,
                    &.open-child {
                        > a,
                        > .nav-header {
                            &::after {
                                transform: rotate(-45deg);
                                transition: transform 0.2s ease; } } }

                    &.close-child {
                        > a,
                        > .nav-header {
                            &::after {
                                transform: rotate(135deg) !important;
                                transition: transform 0.2s ease; } } } } } }

        // Submenu
        &__submenu,
        .nav-child {
            font-size: 15px;
            background-color: $gray-100;
            padding: 20px;

            li {
                a {
                    color: $gray-800;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: block; } } } }

    /**
     * Desktop
     */
    @include media-breakpoint-up(lg) {
        @include linear-gradient(to bottom, #57748e, #2f3e4c);

        &__list {
            display: flex;
            justify-content: center;

            > li {
                > a {
                    align-items: center;
                    color: $white;
                    position: relative;
                    display: flex;
                    transition: background-color .2s ease;
                    height: 44px;
                    padding: 10px 15px;
                    text-transform: uppercase;
                    @include media-breakpoint-up(xl) {
                        padding: 10px 25px; }

                    &:hover {
                        @include hoverLink; } }

                &:not(:last-child) {
                    > a {
                        position: relative;

                        &::after {
                            border-right: 1px dotted rgba($white, 0.5);
                            content: "";
                            display: block;
                            position: absolute;
                            top: 10px;
                            right: 0;
                            bottom: 10px;
                            width: 2px; } } }

                &.parent {
                    position: relative;

                    &:hover {
                        #{$self}__submenu,
                        .nav-child {
                            // display: block
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.2s ease;
                            margin-top: 0; }

                        > a {
                            @include hoverLink; } } } } }

        // Submenu
        &__submenu,
        .nav-child {
            background-color: $white;
            border: 1px solid $gray-300;
            border-top: 3px solid $gray-600;
            padding-top: 15px;
            // display: none
            opacity: 0;
            visibility: hidden;
            display: flex;
            flex-wrap: wrap;
            width: 320px;
            position: absolute;
            top: 44px;
            left: 0;
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            margin-top: 20px;
            transition: all 0.2s ease;
            z-index: 10;
            text-align: center;

            &::before {
                border-bottom: 8px solid $gray-600;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                content: "";
                position: absolute;
                top: -11px;
                left: 35px;
                height: 0;
                width: 0; }

            li {
                padding: 0 10px;
                margin: 0;
                width: 50%;

                &:not(:last-child) {
                    margin-bottom: 15px; }

                a {
                    color: $gray-600;
                    display: block;

                    &:hover {
                        text-decoration: none;
                        color: $primary; }

                    .image-title {
                        display: block;
                        margin-top: 4px; } } } } } }
