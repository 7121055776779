.pagination {
    &__list {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 15px;
        list-style: none;
        @include media-breakpoint-up(md) {
            font-size: 18px; }


        li {
            margin: 0 4px;
            @include media-breakpoint-up(md) {
                margin: 0 8px; }

            a, span {
                align-items: center;
                color: $gray-800;
                display: flex;
                text-decoration: none;
                line-height: normal;
                padding: 0 8px;
                justify-content: center;
                height: 30px;

                &:hover {
                    color: $primary;
                    text-decoration: none; } }

            &.is-disabled {
                display: none; }

            &.is-active {
                a, span {
                    border: 1px solid $gray-500;
                    border-radius: 3px;
                    color: $gray-500;
                    font-weight: 700; } } } }

    &__result-counter {
        color: $gray-500;
        font-size: 14px;
        margin-top: 10px;
        text-align: center; } }
