.mfp-white-block {
    background-color: $white;
    box-shadow: 0 0 60px 0 rgba(45, 49, 67, 0.2);
    max-width: 470px;
    width: 100%;
    position: relative;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(sm) {
        padding: 30px; }

    &--960 {
        max-width: 960px; } }
