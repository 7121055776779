/* ============================================================================
   INCLUDS
   @mixin ======================================================================== */

@import 'variables';
@import '../../mixins/sass/sprite-img';
@import '../../mixins/sass/sprite-svg';
@import '../../mixins/sass/pos-center';
@import '../../mixins/sass/pos-strech';
@import '../../mixins/sass/responsive-title';
@import '../../mixins/sass/letter-spacing';
@import '../../vendor/bootstrap/bootstrap';
@import '../../mixins/sass/gradient';
@import '../../mixins/sass/font-face';


/**
 * Base
 */
@import './fonts';
@import './base';
@import './typography';
@import './png-icons';

/**
 * Static blocks
 */
@import '../../components/wrapper/wrapper';
@import "../../components/header/header";
@import "../../components/logo/logo";
@import "../../components/mainnav/mainnav";
@import "../../components/hamburger/hamburger";
@import "../../components/breadcrumbs/breadcrumbs";
@import "../../components/content/content";
@import "../../components/sidebar/sidebar";
@import "../../components/module/module";
@import '../../components/textblock/textblock';
@import '../../components/pagination/pagination';
@import "../../components/footer/footer";

/**
 * Dev blocks
 */
// @import "../../components/preloader/preloader"
@import "../../components/topmenu/topmenu";
@import "../../components/m_search/m_search";
@import "../../components/m_cart/m_cart";
@import "../../components/brands-nav/brands-nav";
@import "../../components/sb-menu/sb-menu";
@import "../../components/article/article";
@import "../../components/list-articles/list-articles";
@import "../../components/payments/payments";
@import "../../components/form/form";
@import "../../components/overlay/overlay";
@import "../../components/m_lang-select/m_lang-select";
@import "../../components/close/close";

/**
 * Jshop
 */
@import "../../components/jshop/jshop";


/**
 * Libs styles
 */
@import '../../vendor/magnific-popup/main';
@import '../../vendor/owl-carousel/owl.carousel';
@import '../../vendor/owl-carousel/owl.theme.default';
// @import '../../vendor/go-top/go-top'
@import '../../vendor/jquery-formstyler/jquery.formstyler';
@import '../../vendor/intel-tel-input/intlTelInput';
