.jshop-main-category {
    text-align: center;

    &__title {
        font-size: 16px;
        font-weight: 500;
        padding: 10px;
        line-height: normal;
        margin-top: 10px;

        a {
            color: $gray-800;

            &:hover {
                color: $primary;
                text-decoration: none; } } } }



