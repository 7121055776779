.list-articles {
    $self: &;

    &__item {
        &:not(:last-child) {
            margin-bottom: 30px; } }

    &__title {
        a {
            color: $gray-800;

            &:hover {
                text-decoration: none;
                color: $primary; } } }

    &__date {
        font-size: 14px; }

    &__all {
        text-align: right;
        font-weight: 500;
        margin-top: 20px;

        a {
            color: $gray-800;

            &:hover {
                color: $primary;
                text-decoration: none; } } } }
