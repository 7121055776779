.module {
    &:not(:last-child) {
        margin-bottom: 30px; }

    &__title {
        margin-bottom: 30px; }

    // Sidebar
    .sidebar & {
        border: 1px solid $gray-400;
        background-color: $white;
        padding: 20px;

        .module__title {
            background-color: $gray-700;
            color: $white;
            padding: 8px 20px;
            margin: -21px -21px 15px;
            font-size: 18px;
            line-height: normal;

            &--primary {
                @include linear-gradient(to bottom, #f46a57, #f02e14); } } }

    .header__top & {
        margin-bottom: 0;

        &:not(:first-child) {
            margin-left: 30px; } } }
