.jshop-register {
    &__tabs {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px; }

        li {
            @include responsive-tlt(24px, 36px);
            font-weight: 500;

            a {
                color: rgba($gray-800, 0.2);

                &:hover {
                    color: rgba($gray-800, 0.4); }

                &.active {
                    color: $gray-800; } } } }

    #reg_test_password {
        display: block;

        &:not(.fielderrormsg) {
            display: none; } } }


