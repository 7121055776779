.profile {
    &__data {
        font-size: 16px;

        &-row {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 10px; } }

        &-name {
            font-weight: 500;
            margin-right: 5px; } }

    &__urls {
        margin-top: 20px;

        > div {
            &:not(:last-child) {
                margin-bottom: 5px; } } } }
