.form,
form {
    $self: &;

    label {
        font-size: 14px;
        font-weight: 500; }

    textarea {
        &.form-control {
            height: 120px; } }

    .fielderror {
        border-color: $danger; } }
