.wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: left;
    min-width: 320px;
    max-width: 1920px;
    transition: margin .2s ease;
    position: relative;
    height: 100vh;

    &__content {
        flex: 1; } }
