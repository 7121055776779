
.owl-theme {
	// Styling Next and Prev buttons
	.owl-nav {
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		button[class*='owl-'] {
			background-color: $nav-background;
			color: $nav-color;
			font-size: $nav-font-size;
			margin: $nav-margin;
			padding: $nav-padding !important;
			cursor: pointer;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: inline-flex;
			align-items: center;
			justify-content: center;

			&:hover:not(.disabled) {
				background-color: $nav-background-hover;
				color: $nav-color-hover;
				text-decoration: none;
			}

			.icon {
				font-size: 20px;

				@include media-breakpoint-up(lg) {
					font-size: 24px;
				}
			}

			&:focus {
				outline: none;
			}
			&.disabled {
				opacity: $nav-disabled-opacity;
				cursor: default;
			}
		}
		.owl-prev  {
			left: -12px;
		}
		.owl-next {
			right: -12px;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
		align-items: center;
		display: flex;
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		justify-content: center;
		margin-top: 20px;

		.owl-dot {
			display: block;

			span {
				width: $dot-width;
				height: $dot-height;
				background: $dot-background;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: $dot-rounded;
			}

			&:not(:last-child) {
				margin-right: $dot-margin;
			}

			&.active,
			&:hover {
				span {
					background: $dot-background-active;
				}
			}
		}
	}
}
