/**
 * Hamburger
 */

.hamburger {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    outline: none;
    transition: transform .2s ease;
    justify-content: space-between;
    margin-right: 8px;
    height: 21px;
    width: 28px;
    z-index: 10;

    &::before,
    &::after,
    > span {
        background-color: $gray-800;
        border-radius: 3px;
        content: "";
        display: block;
        transition: transform .2s ease;
        height: 3px;
        width: 100%; }

    &::before,
    &::after {
        max-width: 80%; }

    &.is-active,
    .show & {
        transition: transform .3s ease;

        &::before, &::after, > span {
            width: 100%; }

        span {
            left: -50px;
            opacity: 0; }

        &::before, &::after {
            top: 50%;
            position: absolute; }
        &::after {
            transform: rotate(-45deg); }
        &::before {
            transform: rotate(45deg); } } }
