.article {
    //border: 1px solid $gray-400
    //background-color: $white
    //padding: 20px

    &__image {
        margin-bottom: 20px; }

    &__info-block {
        color: $gray-500;
        font-style: italic;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        display: flex;
        margin-top: 55px;

        .icon {
            margin-right: 5px;
            font-size: 16px; }

        > div,
        > dd {
            align-items: center;
            display: flex;

            &:not(:last-child) {
                margin-right: 30px; } } } }
