.m_cart {
    $self: &;

    &__link {
        align-items: center;
        color: $gray-600;
        display: inline-flex;

        &:hover {
            text-decoration: none; } }

    &__left {
        display: block;
        position: relative;

        .icon {
            font-size: 30px; } }

    &__count {
        background-color: $gray-500;
        border-radius: 10px;
        color: $white;
        padding: 0 7px;
        font-size: 10px;
        height: 16px;
        position: absolute;
        top: -5px;
        right: -8px; }

    &__right {
        display: none;
        margin-left: 15px;
        @include media-breakpoint-up(lg) {
            display: block; } }

    &__tlt {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        display: block; }

    &__summ {
        font-size: 14px;
        display: block; }

    // Active state
    &.is-active {
        #{$self} {
            &__link {
                color: $gray-800; }

            &__count {
                background-color: $green; } } } }
