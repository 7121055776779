.alert {
    position: relative;

    .close {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 24px;
        color: $gray-800;
        line-height: 1;
        opacity: 0.9;

        &:hover {
            text-decoration: none;
            opacity: 1; } } }
