#mxcpr {
    display: none; }

.stars_no_active {
    height: 16px;
    background-image: url(../images/star.gif);
    background-repeat: repeat-x;
    background-position: 0 0; }

.stars_active {
    height: 16px;
    background-image: url(../images/star.gif);
    background-repeat: repeat-x;
    background-position: 0 -16px; }

.field-calendar {
    .input-append {
        align-items: center;
        display: flex; }
    .btn {
        margin-left: 5px;
        height: 38px; }

    .icon-calendar {
        background-image: url(../images/calendar.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        display: block;
        height: 18px;
        width: 18px; } }

.star {
    color: $red; }



@import "./jshop-main-category/jshop-main-category";
// @import "/jshop-categories/jshop-categories"
@import "./product/product";
@import "./jshop-list-product/jshop-list-product";
@import "./extra_fields/extra_fields";
@import "./jshop-raiting/jshop-raiting";
@import "./jshop-cart-table/jshop-cart-table";
@import "./jshop-product-qty/jshop-product-qty";
@import "./productfull/productfull";
@import "./quickview/quickview";
@import "./jshop-register/jshop-register";
@import "./profile/profile";
@import "./profile/order";
@import "./m_filter/m_filter";
@import "./checkout/checkout";
//@import "./profile/order-message"
//@import "./profile/order-info"
//@import "./profile/order-card"
//@import "./profile/order-messages"
//@import "./profile/user-products"
