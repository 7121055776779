.btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    line-height: normal;

    &-s32 {
        height: 32px; }

    &-s40 {
        height: 40px; }

    &-s50 {
        font-size: 16px;
        height: 50px; }

    &-s60 {
        font-size: 16px;
        height: 60px; }

    &.btn-outline-brown {
        color: $brown;

        &:hover {
            color: $white; } } }
