// Font
$font-family-base: "Roboto", sans-serif;

// Grid containers
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
);

// Links
//$link-color: $blue;
//$link-decoration:                         none;
//$link-hover-color:                        darken($link-color, 15%);
//$link-hover-decoration:                   underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
//$emphasized-link-hover-darken-percentage: 15% !default;

// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 3),
		10: ($spacer * 0.625), // 10px
		15: ($spacer * 0.9375), // 15px
		20: ($spacer * 1.25), // 20px
		25: ($spacer * 1.5625), // 25px
		30: ($spacer * 1.875), // 30px
		40: ($spacer * 2.5), // 40px
		50: ($spacer * 3.125), // 50px
		60: ($spacer * 3.75), // 60px
		80: ($spacer * 5), // 80px
		90: ($spacer * 5.625), // 90px
		100: ($spacer * 6.25) // 100px
	),
	$spacers
);

/*
 * Color system
 * ---------------------------------------------
 */
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6a7d90;
$gray-700: #495057;
$gray-800: #2f3e4c;
$gray-900: #212529;
$black:    #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
	(
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900
	),
	$grays
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #f46a57;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #3cba58;
$teal:    #20c997;
$cyan:    #17a2b8;
$brown:   #b18248;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
	(
		"blue":       $blue,
		"indigo":     $indigo,
		"purple":     $purple,
		"pink":       $pink,
		"red":        $red,
		"orange":     $orange,
		"yellow":     $yellow,
		"green":      $green,
		"teal":       $teal,
		"cyan":       $cyan,
		"white":      $white,
		"gray":       $gray-600,
		"gray-dark":  $gray-800
	),
	$colors
);

$primary:       $red;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
$brown: 		$brown;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
	(
		"primary":    $primary,
		"secondary":  $secondary,
		"success":    $success,
		"info":       $info,
		"warning":    $warning,
		"danger":     $danger,
		"light":      $light,
		"dark":       $dark,
		"brown":      $brown
	),
	$theme-colors
);

/**
 * Buttons
 */
$btn-border-radius: 3px;
$btn-border-radius-sm: 2px;
$btn-border-radius-lg: 4px;

$btn-font-size: 0.875rem;
$btn-font-size-sm: 0.875rem;
$btn-font-size-lg: 1.125rem;

// Body
$body-color: $gray-800;
