.order {
    border: 1px solid #dedede;
    color: $gray-700;
    font-size: 16px;

    &:not(:first-child) {
        margin-top: -1px; }

    &__left {
        border-right: 1px solid #e8e8e8;
        padding: 15px;
        height: 100%;
        @include media-breakpoint-up(lg) {
            padding: 20px; } }

    &__number {
        color: $blue;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px; }

    &__date {
        color: $gray-700;
        font-size: 14px; }

    &__middle {
        height: 100%;

        &-top {
            border-bottom: 1px solid #e8e8e8;
            padding: 15px;
            line-height: 1.2;
            @include media-breakpoint-up(lg) {
                padding: 15px 25px; } }

        &-bottom {
            padding: 10px; } }

    &__status {
        align-items: center;
        display: flex;
        font-size: 14px;

        &::before {
            background-color: $gray-200;
            content: "";
            border-radius: 50%;
            margin-right: 10px;
            height: 8px;
            width: 8px; }

        &--processed {
            &::before {
                background-color: $yellow; } }

        &--confirmed {
            &::before {
                background-color: $blue; } }

        &--completed {
            &::before {
                background-color: $green; } } }

    &__product-collapse {
        border-bottom: 1px dashed $gray-600;
        color: $gray-600;
        text-decoration: none;
        font-weight: 500;

        &:hover {
            color: $gray-700;
            border-bottom-color: $gray-700;
            text-decoration: none; } }

    &__right {
        border-left: 1px solid #e8e8e8;
        padding: 10px 15px;
        height: 100%;
        @include media-breakpoint-up(lg) {
            padding: 10px 20px; } }

    &__price {
        > span {
            color: $gray-700; } }

    &__cancel-order {
        color: $red;
        font-size: 14px;

        &:hover {
            color: $gray-700; } }

    .btn-danger {
        &:not(:hover) {
            background-color: rgba($red, 0.15);
            border-color: rgba($red, 0.15);
            color: $red; } }

    .btn-primary {
        &:not(:hover) {
            background-color: rgba($blue, 0.15);
            border-color: rgba($blue, 0.15);
            color: $blue; } } }
