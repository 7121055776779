.sb-menu {
    $self: &;

    &__list {
        font-size: 15px;
        font-weight: 500;
        line-height: normal;

        > li {
            > a {
                color: $gray-800;

                &:hover {
                    color: $primary;
                    text-decoration: none; } }

            &:not(:last-child) {
                margin-bottom: 15px; }

            &.is-active {
                > a {
                    color: $primary; } }

            &.parent {
                > a {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;

                    &::after {
                        border-top: 1px solid $gray-800;
                        border-right: 1px solid $gray-800;
                        content: "";
                        display: block;
                        margin-left: 10px;
                        transform: rotate(135deg);
                        transition: transform 0.2s ease;
                        height: 6px;
                        width: 6px; } }

                &.is-active,
                &.open-child {
                    a {
                        &::after {
                            transform: rotate(-45deg);
                            transition: transform 0.2s ease; } } }

                &.close-child {
                    a {
                        &::after {
                            transform: rotate(135deg) !important;
                            transition: transform 0.2s ease; } } } } } }

    // Counter
    &__counter {
        align-items: center;
        background-color: $gray-500;
        border-radius: 10px;
        color: $white;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 10px;
        line-height: normal;
        height: 15px;
        margin-left: 10px; }

    // Submenu
    &__submenu {
        color: #bababa;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        margin-top: 20px;
        margin-left: 20px;

        li {
            &:not(:last-child) {
                margin-bottom: 18px; }

            a {
                align-items: center;
                display: inline-flex;
                color: $gray-800;

                &:hover {
                    color: $primary;
                    text-decoration: none;

                    #{$self}__counter {
                        background-color: $primary; } } }

            &.is-active {
                a {
                    color: $success; } } } } }
