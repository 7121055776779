.quickview {
    &.mfp-white-block {
        max-width: 960px; }

    .mfp-close {
        color: #dedede;
        font-size: 50px;
        @include media-breakpoint-up(lg) {
            top: 10px;
            right: 10px;

            &:active {
                top: 10px;
                right: 10px; } } }

    .productfull__charact-row {
        > span {
            background-color: $white; } }

    .owl-prev,
    .owl-next {
        .icon {
            font-size: 20px !important; } } }
