.product {
    $self: &;

    height: 100%;
    transition: border-color ease 0.3s;


    > .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }

    &:hover {
        border-color: rgba($primary, 0.5);
        transition: border-color ease 0.3s; }

    &__image {
        position: relative;
        text-align: center;
        margin-bottom: 10px;

        &:hover {
            #{$self}__quickview-btn {
                transition: opacity 0.2s ease;
                opacity: 0.9; } } }

    &__badge {
        position: absolute;
        top: 0;
        right: 0; }

    &__label {
        position: absolute;
        top: 0;
        left: 0; }

    &__header {
        margin-bottom: 15px; }

    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;

        a {
            color: $gray-800;

            &:hover {
                color: $primary;
                text-decoration: none; } } }

    &__raiting {
        margin-bottom: 10px; }


    &__price {
        color: $gray-800;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        order: 2;

        @include media-breakpoint-only(lg) {
            margin-bottom: 10px;
            order: 1; }

        &-row {
            align-items: center;
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(lg) {
                align-items: flex-end;
                flex-direction: row; } }

        &-curr,
        .currencycode {
            font-size: 14px; } }

    &__shipping-txt {
        font-size: 12px;
        order: 2;
        margin-left: 10px; }

    &__oldprice {
        color: rgba($gray-800, 0.3);
        font-size: 14px;
        font-weight: 700;
        text-decoration: line-through;
        order: 1;
        @include media-breakpoint-up(lg) {
            order: 2;
            margin-left: 10px; } }

    // Extra fields
    &__extra_fields {
        margin-top: 10px;

        .extra_fields {
            &__row {
                font-size: 12px; } } }


    // Buttons
    &__btns {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        .btn {
            &:not(:last-child) {
                margin-right: 6px; } } }

    &__add-to-cart {
        align-items: center;
        color: $gray-800;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.28px;
        line-height: 1.2;
        display: inline-flex;

        .icon {
            margin-right: 8px; }

        &:hover {
            text-decoration: none;
            color: $primary; } }

    &__btn-addcart {
        height: 32px;
        width: 32px; } }
