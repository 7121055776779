.m_search {
    @include media-breakpoint-up(md) {
        width: 100%; }

    @include media-breakpoint-down(sm) {
        background-color: $white;
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 7px;
        z-index: 10;
        padding-left: 15px;
        padding-right: 15px;

        &.is-active {
            display: block; }

        &__mobile-toggle {
            display: block;
            color: $gray-800;

            .icon {
                font-size: 20px;
                pointer-events: none; } } }

    &__form {
        align-items: center;
        position: relative;
        display: flex;

        .form-control {
            border-color: transparent;
            font-size: 14px;
            height: 40px;
            padding-left: 15px;
            padding-right: 40px;
            transition: border-color .2s ease;
            @include media-breakpoint-up(md) {
                background-color: $gray-100; }

            &::placeholder {
                color: $gray-800; }

            &:focus {
                box-shadow: none;
                @include media-breakpoint-up(lg) {
                    border-color: $gray-300;
                    transition: border-color .2s ease; } } }

        .btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;

            .icon {
                font-size: 18px; }

            &:hover {
                .icon {
                    fill: $primary; } } } }

    // Result
    &__result {
        width: 100%; } }
