.checkout {
    &__header {
        border-bottom: 1px solid $gray-400;
        padding: 0 0 15px;
        margin: 0 0 20px; }

    &__step {
        &-tlt {
            font-size: 18px;
            margin-bottom: 0; } }

    &__body {
        font-size: 14px; }

    .form-control {
        font-size: 14px; }

    &__code_prod {
        color: $gray-500;
        margin-top: 3px; } }
