.logo {
    $self: &;

    &__link {
        color: $gray-800;
        font-size: 30px;
        line-height: 1.2;
        font-weight: 500;

        &:hover {
            color: $red;
            text-decoration: none; } }

    &__slogan {
        color: $gray-800;
        font-size: 15px; }

    &--mobile {
        #{$self} {
            &__link {
                font-size: 24px; }

            &__slogan {
                font-size: 12px; } } } }
