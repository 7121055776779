.m_lang-select {
    font-size: 14px;
    line-height: normal;

    .lang-inline {
        align-items: center;
        display: flex;

        li {
            &:not(:last-child) {
                margin-right: 10px; }

            a {
                display: block; } } } }
