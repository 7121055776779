.breadcrumbs {
    margin-bottom: 15px;
    position: relative;
    z-index: 1;

    &__list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        font-size: 14px;
        line-height: 1.4;

        li {
            a {
                color: #3a4145; }

            &.is-active {
                color: $primary; } } }

    &__divider {
        color: #a7b6c0;
        display: inline-block;
        font-size: 16px;
        margin: 0 8px; } }
