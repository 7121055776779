// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-card-name: 'icon_card';
$icon-card-x: 0px;
$icon-card-y: 0px;
$icon-card-offset-x: 0px;
$icon-card-offset-y: 0px;
$icon-card-width: 50px;
$icon-card-height: 30px;
$icon-card-total-width: 105px;
$icon-card-total-height: 94px;
$icon-card-image: '../images/sprite.png';
$icon-card: (0px, 0px, 0px, 0px, 50px, 30px, 105px, 94px, '../images/sprite.png', 'icon_card', );
$icon-cardmir-name: 'icon_cardmir';
$icon-cardmir-x: 55px;
$icon-cardmir-y: 0px;
$icon-cardmir-offset-x: -55px;
$icon-cardmir-offset-y: 0px;
$icon-cardmir-width: 50px;
$icon-cardmir-height: 30px;
$icon-cardmir-total-width: 105px;
$icon-cardmir-total-height: 94px;
$icon-cardmir-image: '../images/sprite.png';
$icon-cardmir: (55px, 0px, -55px, 0px, 50px, 30px, 105px, 94px, '../images/sprite.png', 'icon_cardmir', );
$icon-mastercard-name: 'icon_mastercard';
$icon-mastercard-x: 0px;
$icon-mastercard-y: 35px;
$icon-mastercard-offset-x: 0px;
$icon-mastercard-offset-y: -35px;
$icon-mastercard-width: 50px;
$icon-mastercard-height: 30px;
$icon-mastercard-total-width: 105px;
$icon-mastercard-total-height: 94px;
$icon-mastercard-image: '../images/sprite.png';
$icon-mastercard: (0px, 35px, 0px, -35px, 50px, 30px, 105px, 94px, '../images/sprite.png', 'icon_mastercard', );
$icon-visacard-name: 'icon_visacard';
$icon-visacard-x: 55px;
$icon-visacard-y: 35px;
$icon-visacard-offset-x: -55px;
$icon-visacard-offset-y: -35px;
$icon-visacard-width: 50px;
$icon-visacard-height: 30px;
$icon-visacard-total-width: 105px;
$icon-visacard-total-height: 94px;
$icon-visacard-image: '../images/sprite.png';
$icon-visacard: (55px, 35px, -55px, -35px, 50px, 30px, 105px, 94px, '../images/sprite.png', 'icon_visacard', );
$youtube-name: 'youtube';
$youtube-x: 0px;
$youtube-y: 70px;
$youtube-offset-x: 0px;
$youtube-offset-y: -70px;
$youtube-width: 24px;
$youtube-height: 24px;
$youtube-total-width: 105px;
$youtube-total-height: 94px;
$youtube-image: '../images/sprite.png';
$youtube: (0px, 70px, 0px, -70px, 24px, 24px, 105px, 94px, '../images/sprite.png', 'youtube', );
$spritesheet-width: 105px;
$spritesheet-height: 94px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($icon-card, $icon-cardmir, $icon-mastercard, $icon-visacard, $youtube, );
$spritesheet: (105px, 94px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
