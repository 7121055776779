.footer {
    margin-top: 30px;

    /**
     * Top
     */
    &__top {
        @include linear-gradient(to bottom, #57748e, #2f3e4c);
        color: $white;
        padding-top: 30px;
        padding-bottom: 15px; }

    &__logo {
        .logo {
            &__link {
                font-size: 28px;
                color: $gray-400;

                &:hover {
                    color: $white; } }

            &__slogan {
                color: $gray-400;
                font-size: 14px; } } }

    .module {
        &__title {
            color: $gray-400;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px; } }

    // Nav
    &__nav {
        &-list {
            font-size: 14px;

            li {
                &:not(:last-child) {
                    margin-bottom: 10px; }

                a {
                    color: $gray-300;

                    &:hover {
                        color: $white;
                        text-decoration: none; } }

                &.is-active,
                &.active {
                    a {
                        color: $green; } } } } }
    // Phone
    &__phone {
        margin-top: 30px;

        &-link {
            font-size: 20px;
            font-weight: 500;
            color: $gray-400;

            &:hover {
                color: $white;
                text-decoration: none; } }

        &-city {
            color: $gray-500;
            font-size: 14px; } }

    /**
     * Bottom
     */
    &__bottom {
        padding-top: 10px;
        padding-bottom: 10px; } }

