.topmenu {
    &__list {
        align-items: center;
        display: flex;
        font-size: 14px;

        li {
            &:not(:last-child) {
                margin-right: 15px; }

            a {
                color: $gray-800;
                text-decoration: underline;

                &:hover {
                    color: $primary;
                    text-decoration: underline; } }

            &.active {
                a {
                    color: $green; } } } } }
